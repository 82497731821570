import {
    IActivePatients,
    IActiveStudies,
    ICodeName,
    IDepartments,
    ILanguageData,
    ILastPatOpened,
    ILink,
    InitialLoginType,
    IPossibleMacros,
    IPossibleStudies,
    ISecurityItems,
} from '../context/loginContext';

type Action =
    | {
          type: 'LOGIN';
          isLoggedIn: boolean;
          userId: string;
          practitionerRoleId: string;
          userName: string;
          sessionId: string;
          securityItems: ISecurityItems;
          iceServers: Array<string>;
          possibleMacros: Array<IPossibleMacros>;
          departments: IDepartments[];
          activeStudies: Array<IActiveStudies>;
          activeUserId: string;
          activeProfile: string;
          termsHTML: string;
          privacyHTML: string;
          extraLinks: Array<ILink>;
          possibleStudies: Array<IPossibleStudies>;
          addConsignationsToAsk: Array<string>;
          encounterId: string;
          encounterTitle: string;
          activeSidebarItem: string;
          activePatients: Array<IActivePatients>;
          activePatient: string;
          gdt: {
              id: string;
          };
          lastPatOpened: Array<ILastPatOpened>;
          anmeldeContexts: Array<ICodeName>;
          activeLanguage: ILanguageData;
      }
    | {
          type: 'LOGOUT';
      }
    | {
          type: 'SETACTIVEUSERID';
          activeUserId: string;
      }
    | {
          type: 'SETACTIVEPROFILE';
          activeProfile: string;
      }
    | {
          type: 'SETACTIVEPATIENT';
          activePatient: string;
      }
    | {
          type: 'SETSECURITYITEMS';
          securityItems: ISecurityItems;
      }
    | {
          type: 'SETSECURITYITEMSSTUDY';
          securityItemsStudy: ISecurityItems;
      }
    | {
          type: 'SETTERMSHTML';
          termsHTML: any;
      }
    | {
          type: 'SETPRIVACYHTML';
          privacyHTML: any;
      }
    | {
          type: 'SETREDIRECTUSER';
          redirectUser: string;
          redirectPwd: string;
      }
    | {
          type: 'RESETREDIRECTUSER';
          redirectUser: string;
          redirectPwd: string;
      }
    | {
          type: 'SETOFINITPAGEDONE';
          setOfInitPageDone: boolean;
      }
    | {
          type: 'SETCHECKINAUTH';
          userId: string;
          activeUserId: string;
          sessionId: string;
      }
    | {
          type: 'SETFINALSECURITYITEMSORDER';
          finalSecurityItemsOrder: string[];
      }
    | {
          type: 'SETDEPARTMENTS';
          departments: IDepartments[];
      }
    | {
          type: 'SETENCOUNTER';
          encounterId: string;
          encounterTitle: string;
      }
    | {
          type: 'SETACTIVESIDEBARITEM';
          activeSidebarItem: string;
      }
    | {
          type: 'SETACTIVEPATIENTS';
          activePatients: IActivePatients[];
      }
    | {
          type: 'SETGDTDATA';
          gdt: {
              id: string;
          };
      }
    | {
          type: 'RESETGDTDATA';
          gdt: {
              id: string;
          };
      }
    | {
          type: 'SETACTIVELANGUAGE';
          activeLanguage: ILanguageData;
      };

export const loginReducer = (state: InitialLoginType, action: Action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                isLoggedIn: true,
                userId: action.userId,
                practitionerRoleId: action.practitionerRoleId,
                userName: action.userName,
                sessionId: action.sessionId,
                securityItems: {
                    CHARTS: action.securityItems.CHARTS,
                    CHARTS_WEIGHT: action.securityItems.CHARTS_WEIGHT,
                    CHARTS_FATMASS: action.securityItems.CHARTS_FATMASS,
                    CHARTS_BLOODSUGAR: action.securityItems.CHARTS_BLOODSUGAR,
                    CHARTS_FLI: action.securityItems.CHARTS_FLI,
                    CHARTS_SAM: action.securityItems.CHARTS_SAM,
                    CHARTS_ACTIVITY: action.securityItems.CHARTS_ACTIVITY,
                    TABLE_VAL: action.securityItems.TABLE_VAL,
                    PATLIST: action.securityItems.PATLIST,
                    DOKUMENT: action.securityItems.DOKUMENT,
                    FOTOS: action.securityItems.FOTOS,
                    FRAGEBOGEN: action.securityItems.FRAGEBOGEN,
                    MEDIKATION: action.securityItems.MEDIKATION,
                    TAGESPLAN: action.securityItems.TAGESPLAN,
                    TERMINE: action.securityItems.TERMINE,
                    VIDEO: action.securityItems.VIDEO,
                    JOURNEY: action.securityItems.JOURNEY,
                    TODO: action.securityItems.TODO,
                    TAB_ORDER: action.securityItems.TAB_ORDER,
                },
                iceServers: action.iceServers,
                possibleMacros: action.possibleMacros,
                departments: action.departments,
                activeStudies: action.activeStudies,
                activeUserId: action.activeUserId,
                activeProfile: action.activeProfile,
                termsHTML: action.termsHTML,
                privacyHTML: action.privacyHTML,
                extraLinks: action.extraLinks,
                possibleStudies: action.possibleStudies,
                addConsignationsToAsk: action.addConsignationsToAsk,
                encounterId: action.encounterId,
                encounterTitle: action.encounterTitle,
                activeSidebarItem: action.activeSidebarItem,
                activePatients: action.activePatients ? action.activePatients : [],
                activePatient: action.activePatient,
                lastPatOpened: action.lastPatOpened ? action.lastPatOpened : [],
                anmeldeContexts: action.anmeldeContexts ? action.anmeldeContexts : [],
            };
        case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: false,
                setOfInitPageDone: false,
                userId: '',
                practitionerRoleId: '',
                userName: '',
                sessionId: '',
                securityItems: {
                    CHARTS: false,
                    CHARTS_WEIGHT: false,
                    CHARTS_FATMASS: false,
                    CHARTS_BLOODSUGAR: false,
                    CHARTS_FLI: false,
                    CHARTS_SAM: false,
                    CHARTS_ACTIVITY: false,
                    TABLE_VAL: false,
                    PATLIST: false,
                    DOKUMENT: false,
                    FOTOS: false,
                    FRAGEBOGEN: false,
                    MEDIKATION: false,
                    TAGESPLAN: false,
                    TERMINE: false,
                    VIDEO: false,
                    JOURNEY: false,
                    TODO: [],
                    TAB_ORDER: '',
                },
                securityItemsStudy: {
                    CHARTS: false,
                    CHARTS_WEIGHT: false,
                    CHARTS_FATMASS: false,
                    CHARTS_BLOODSUGAR: false,
                    CHARTS_FLI: false,
                    CHARTS_SAM: false,
                    CHARTS_ACTIVITY: false,
                    TABLE_VAL: false,
                    PATLIST: false,
                    DOKUMENT: false,
                    FOTOS: false,
                    FRAGEBOGEN: false,
                    MEDIKATION: false,
                    TAGESPLAN: false,
                    TERMINE: false,
                    VIDEO: false,
                    JOURNEY: false,
                    TODO: [],
                    TAB_ORDER: '',
                },
                finalSecurityItemsOrder: [],
                iceServers: [],
                possibleMacros: [],
                departments: [],
                activeStudies: [],
                activeUserId: '',
                activeProfile: '',
                termsHTML: '',
                privacyHTML: '',
                extraLinks: [],
                possibleStudies: [],
                addConsignationsToAsk: [],
                encounterId: '',
                encounterTitle: '',
                activeSidebarItem: '',
                activePatients: [],
                activePatient: '',
                gdt: {
                    id: '',
                },
                lastPatOpened: [],
                anmeldeContexts: [],
            };
        case 'SETACTIVEUSERID':
            return {
                ...state,
                activeUserId: action.activeUserId,
            };
        case 'SETSECURITYITEMS':
            return {
                ...state,
                securityItems: {
                    CHARTS: action.securityItems.CHARTS,
                    CHARTS_WEIGHT: action.securityItems.CHARTS_WEIGHT,
                    CHARTS_FATMASS: action.securityItems.CHARTS_FATMASS,
                    CHARTS_BLOODSUGAR: action.securityItems.CHARTS_BLOODSUGAR,
                    CHARTS_FLI: action.securityItems.CHARTS_FLI,
                    CHARTS_SAM: action.securityItems.CHARTS_SAM,
                    CHARTS_ACTIVITY: action.securityItems.CHARTS_ACTIVITY,
                    TABLE_VAL: action.securityItems.TABLE_VAL,
                    PATLIST: action.securityItems.PATLIST,
                    DOKUMENT: action.securityItems.DOKUMENT,
                    FOTOS: action.securityItems.FOTOS,
                    FRAGEBOGEN: action.securityItems.FRAGEBOGEN,
                    MEDIKATION: action.securityItems.MEDIKATION,
                    TAGESPLAN: action.securityItems.TAGESPLAN,
                    TERMINE: action.securityItems.TERMINE,
                    VIDEO: action.securityItems.VIDEO,
                    JOURNEY: action.securityItems.JOURNEY,
                    TODO: action.securityItems.TODO,
                    TAB_ORDER: action.securityItems.TAB_ORDER,
                },
            };
        case 'SETSECURITYITEMSSTUDY':
            return {
                ...state,
                securityItemsStudy: {
                    CHARTS: action.securityItemsStudy.CHARTS,
                    CHARTS_WEIGHT: action.securityItemsStudy.CHARTS_WEIGHT,
                    CHARTS_FATMASS: action.securityItemsStudy.CHARTS_FATMASS,
                    CHARTS_BLOODSUGAR: action.securityItemsStudy.CHARTS_BLOODSUGAR,
                    CHARTS_FLI: action.securityItemsStudy.CHARTS_FLI,
                    CHARTS_SAM: action.securityItemsStudy.CHARTS_SAM,
                    CHARTS_ACTIVITY: action.securityItemsStudy.CHARTS_ACTIVITY,
                    TABLE_VAL: action.securityItemsStudy.TABLE_VAL,
                    PATLIST: action.securityItemsStudy.PATLIST,
                    DOKUMENT: action.securityItemsStudy.DOKUMENT,
                    FOTOS: action.securityItemsStudy.FOTOS,
                    FRAGEBOGEN: action.securityItemsStudy.FRAGEBOGEN,
                    MEDIKATION: action.securityItemsStudy.MEDIKATION,
                    TAGESPLAN: action.securityItemsStudy.TAGESPLAN,
                    TERMINE: action.securityItemsStudy.TERMINE,
                    VIDEO: action.securityItemsStudy.VIDEO,
                    JOURNEY: action.securityItemsStudy.JOURNEY,
                    TODO: action.securityItemsStudy.TODO,
                    TAB_ORDER: action.securityItemsStudy.TAB_ORDER,
                },
            };
        case 'SETACTIVEPROFILE':
            return {
                ...state,
                activeProfile: action.activeProfile,
            };
        case 'SETTERMSHTML':
            return {
                ...state,
                termsHTML: action.termsHTML,
            };
        case 'SETPRIVACYHTML':
            return {
                ...state,
                privacyHTML: action.privacyHTML,
            };
        case 'SETREDIRECTUSER':
            return {
                ...state,
                redirectUser: action.redirectUser,
                redirectPwd: action.redirectPwd,
            };
        case 'RESETREDIRECTUSER':
            return {
                ...state,
                redirectUser: '',
                redirectPwd: '',
            };
        case 'SETOFINITPAGEDONE':
            return {
                ...state,
                setOfInitPageDone: action.setOfInitPageDone,
            };
        case 'SETCHECKINAUTH':
            return {
                ...state,
                userId: action.userId,
                activeUserId: action.activeUserId,
                sessionId: action.sessionId,
            };
        case 'SETFINALSECURITYITEMSORDER':
            return {
                ...state,
                finalSecurityItemsOrder: action.finalSecurityItemsOrder,
            };
        case 'SETDEPARTMENTS':
            return {
                ...state,
                departments: action.departments,
            };
        case 'SETENCOUNTER':
            return {
                ...state,
                encounterId: action.encounterId,
                encounterTitle: action.encounterTitle,
            };
        case 'SETACTIVESIDEBARITEM':
            return {
                ...state,
                activeSidebarItem: action.activeSidebarItem,
            };
        case 'SETACTIVEPATIENTS':
            return {
                ...state,
                activePatients: action.activePatients,
            };
        case 'SETACTIVEPATIENT':
            return {
                ...state,
                activePatient: action.activePatient,
            };
        case 'SETGDTDATA':
            return {
                ...state,
                gdt: {
                    id: action.gdt.id,
                },
            };
        case 'RESETGDTDATA':
            return {
                ...state,
                gdt: {
                    id: '',
                },
            };
        case 'SETACTIVELANGUAGE':
            return {
                ...state,
                activeLanguage: action.activeLanguage,
            };
        default:
            return state;
    }
};
