import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../../Routes';
import StyledButton from '../../components/Buttons/StyledButton';
import { StyledMainCardTitle, StyledProfileCard } from '../../components/Cards/StyledCard';
import { RightDiv } from '../../components/Div/StyledDiv';
import { LottieButtonLoadingSpinner } from '../../components/Loading/Loading';
import ConsignationWaitForLetterModal from '../../components/Modals/ConsignationModal/ConsignationWaitForLetterModal';
import ConsignationWaitModal from '../../components/Modals/ConsignationModal/ConsignationWaitModal';
import DoDoubleOptInModal from '../../components/Modals/DoDoubleOptInModal/DoDoubleOptInModal';
import DoDoubleOptInViaLinkModal from '../../components/Modals/DoDoubleOptInModal/DoDoubleOptInViaLinkModal';
import TwoFactorAuthModal from '../../components/Modals/TwoFALoginModal/TwoFALoginModal';
import { StyledFormLabelForInput } from '../../components/StyledForm/SyledForm';
import Consignation from '../../components/User/Consignation';
import ConsignationAdd from '../../components/User/ConsignationAdd';
import InitialPassword from '../../components/User/InitialPassword';
import AlertContext, { AlertOnHide, AlertType } from '../../context/alertContext';
import AppPropsContext from '../../context/appPropsContext';
import ExecuteRuleContext from '../../context/executeRuleContext';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { PROFILE_HOME, securityItemNames } from '../../globals/global';
import useHandleLogout from '../../hooks/useHandleLogout';
import { useLanguage } from '../../hooks/useLanguage';
import { usePersonalData } from '../../hooks/usePersonalData';
import { useProfileSwitch } from '../../hooks/useProfileSwitch';
import {
    IAuthorizeAsync,
    addPlannedStudy,
    authorizeAsync,
    executeRule,
    getPrivacyHTML,
    getTermsHTML,
    setActivePatId,
} from '../../services/RestServices';
import { getPatientProfilePictureWL } from '../../services/fhir/FHIRPatientResource';
import { showAlertMessage } from '../../utils/alertHandling';
import { calcButtonPaddingSize } from '../../utils/lottieUtils';
import { getQueryParams } from '../../utils/urlUtils';

interface ILoginURLParams {
    addPlannedStudy?: string;
}

const Login = () => {
    const navigate = useNavigate();
    const { apstate } = useContext(AppPropsContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [initPassword, setInitPassword] = useState(false);
    const [consignationForm, showConsignationForm] = useState(false);
    const [consignationAddForm, showConsignationAddForm] = useState(false);
    const [consignationWaitForm, showConsignationWaitForm] = useState(false);
    const [consignationWaitForLetterForm, showConsignationWaitForLetterForm] = useState(false);
    const [doubleOptInForm, showDoubleOptInForm] = useState(false);
    const [doubleOptInViaLinkForm, showDoubleOptInViaLinkForm] = useState(false);
    const [twoFAModalShow, set2FAModalShow] = useState(false);
    const [notGiven, setNotGiven] = useState(false);
    const [consig, setConsignation] = useState('');
    const [doubleOptInMissingAfterPWDChange, setDoubleOptInMissingAfterPWDChange] = useState(false); //wird nur gebraucht wenn forcePWD + doubleOptInMissing kommt, dann muss zuerst forcePWD ziehen
    const [autoLogin, setAutoLogin] = useState(false);
    const [activeStudyName, setActiveStudyName] = useState('');

    const [showLoginButtonLoadingSpinner, setShowLoginButtonLoadingSpinner] = useState(false);

    const { state, dispatch } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const { alertdispatch } = useContext(AlertContext);
    const { executerulestate } = useContext(ExecuteRuleContext);

    const { getAndSetPersonalData, resetPersonalData } = usePersonalData();

    const { handleLogout } = useHandleLogout();

    const { t } = useLanguage();

    const loginButtonRef = useRef<any>(null);

    const { setSecurityItems, getUserId } = useProfileSwitch();

    const location = useLocation();

    useEffect(() => {
        if (state.redirectUser && state.redirectUser.length > 0) {
            setUsername(state.redirectUser);
            setPassword(state.redirectPwd);
            setAutoLogin(true);
            return () => {
                dispatch({
                    type: 'RESETREDIRECTUSER',
                });
            };
        }
    }, []);

    useEffect(() => {
        if (autoLogin)
            if (loginButtonRef) {
                loginButtonRef.current.click();
            }
        return () => {
            setAutoLogin(false);
        };
    }, [autoLogin]);

    useEffect(() => {
        const setCurrentActivePatId = async (activeUserId: string) => {
            const changeRes = await setActivePatId(state.sessionId, activeUserId);
            if (!changeRes.SUCCESS) {
                // console.log('profile change NOT successful');
            }
        };

        if (activeStudyName !== '') {
            setSecurityItems(activeStudyName);

            const activeUserId = getUserId(activeStudyName);

            dispatch({
                type: 'SETACTIVEUSERID',
                activeUserId: activeUserId,
            });

            dispatch({
                type: 'SETACTIVEPROFILE',
                activeProfile: activeStudyName,
            });

            setCurrentActivePatId(activeUserId);
        }
        return () => {
            setActiveStudyName('');
        };
    }, [activeStudyName]);

    const getAddConsignationToAsk = async (
        sessionId: string,
        loginData_addConsignationToAsk: string | undefined,
        urlParam_addConsignationToAsk: string | undefined,
    ) => {
        if (loginData_addConsignationToAsk) {
            return loginData_addConsignationToAsk;
        } else if (urlParam_addConsignationToAsk) {
            const response = await addPlannedStudy(sessionId, urlParam_addConsignationToAsk);
            if (response.success && response.success === true) {
                if (response.addConsignationsToAsk) {
                    const responseArray: any[] = [];
                    responseArray.push(response.addConsignationsToAsk);
                    return responseArray;
                }
            } else {
                showAlertMessage({
                    alertTitle: 'Einwilligungserklärung (addPlannedStudy)',
                    alertTxt: response.error,
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            }
        } else {
            return [];
        }

        return [];
    };

    const loginHandling = async (props: IAuthorizeAsync) => {
        setShowLoginButtonLoadingSpinner(true);

        loginButtonRef.current.blur();

        const loginData = await authorizeAsync(props);

        setShowLoginButtonLoadingSpinner(false);

        /* get URL Parameters */
        const hashParams: ILoginURLParams = getQueryParams(location.search);
        let addPlannedStudyUrlParam = false;
        /* external addPlannedStudy request */
        if (hashParams.addPlannedStudy) {
            addPlannedStudyUrlParam = true;
        }

        if (loginData.error) {
            let msg = loginData.error;
            if (loginData.errorMsg && loginData.errorMsg.length > 0) {
                msg = loginData.errorMsg;
            }
            showAlertMessage({
                alertTitle: 'Login',
                alertTxt: msg,
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        } else if (!loginData) {
            showAlertMessage({
                alertTitle: 'Login',
                alertTxt: 'Kann keine Verbindung zum Server herstellen',
                alertType: AlertType.error,
                onHide: AlertOnHide.onlyClose,
                alertdispatch: alertdispatch,
            });
        } else {
            /* execute rule in principa after login */
            if (executerulestate && executerulestate.ruleName.length > 0) {
                const executeRuleRespone = await executeRule(
                    loginData.userId,
                    loginData.sessionId,
                    executerulestate.ruleName,
                    executerulestate.ruleParam,
                    executerulestate.persInfo,
                );
                if (executeRuleRespone === undefined || !executeRuleRespone.SUCCESS) {
                    console.log('problems to executeRule');
                }
            }

            if (loginData.warning === 'needsfa2Secret') {
                set2FAModalShow(true);
            } else {
                const activeStudy =
                    loginData.activeStudies !== undefined
                        ? loginData.activeStudies.find(
                              (element: { studyPatId: any }) => element.studyPatId === loginData.activePatId,
                          )
                        : undefined;

                const activeUserId = loginData.userId;

                /* load termsHTML + privacyHTML*/
                let termsHTML = '';
                let privacyHTML = '';

                if (loginData.consignation !== 'patFinished') {
                    termsHTML = await getTermsHTML(loginData.sessionId);
                    privacyHTML = await getPrivacyHTML(loginData.sessionId);
                }

                /* get possible addConsignation (from authorize or from urlParam addPlannedStudy) */
                const addConsignationResponse = await getAddConsignationToAsk(
                    loginData.sessionId,
                    loginData.addConsignationsToAsk,
                    hashParams.addPlannedStudy,
                );

                dispatch({
                    type: 'LOGIN',
                    userId: loginData.userId,
                    practitionerRoleId: loginData.practitionerRoleId,
                    userName: username,
                    sessionId: loginData.sessionId,
                    securityItems: {
                        CHARTS: loginData.SecurityItems[securityItemNames.CHARTS] === 'true' ? true : false,
                        CHARTS_WEIGHT:
                            loginData.SecurityItems[securityItemNames.CHARTS_WEIGHT] === 'true' ? true : false,
                        CHARTS_FATMASS:
                            loginData.SecurityItems[securityItemNames.CHARTS_FATMASS] === 'true' ? true : false,
                        CHARTS_BLOODSUGAR:
                            loginData.SecurityItems[securityItemNames.CHARTS_BLOODSUGAR] === 'true' ? true : false,
                        CHARTS_FLI: loginData.SecurityItems[securityItemNames.CHARTS_FLI] === 'true' ? true : false,
                        CHARTS_SAM: loginData.SecurityItems[securityItemNames.CHARTS_SAM] === 'true' ? true : false,
                        CHARTS_ACTIVITY:
                            loginData.SecurityItems[securityItemNames.CHARTS_ACTIVITY] === 'true' ? true : false,
                        TABLE_VAL: loginData.SecurityItems[securityItemNames.TABLE_VAL] === 'true' ? true : false,
                        PATLIST: loginData.SecurityItems[securityItemNames.PATLIST] === 'true' ? true : false,
                        DOKUMENT: loginData.SecurityItems[securityItemNames.DOKUMENT] === 'true' ? true : false,
                        FOTOS: loginData.SecurityItems[securityItemNames.FOTOS] === 'true' ? true : false,
                        FRAGEBOGEN: loginData.SecurityItems[securityItemNames.FRAGEBOGEN] === 'true' ? true : false,
                        MEDIKATION: loginData.SecurityItems[securityItemNames.MEDIKATION] === 'true' ? true : false,
                        TAGESPLAN: loginData.SecurityItems[securityItemNames.TAGESPLAN] === 'true' ? true : false,
                        TERMINE: loginData.SecurityItems[securityItemNames.TERMINE] === 'true' ? true : false,
                        VIDEO: loginData.SecurityItems[securityItemNames.VIDEO] === 'true' ? true : false,
                        JOURNEY: loginData.SecurityItems[securityItemNames.JOURNEY] === 'true' ? true : false,
                        TODO: loginData.SecurityItems[securityItemNames.TODO]
                            ? loginData.SecurityItems[securityItemNames.TODO]
                            : [],
                        TAB_ORDER: loginData.SecurityItems[securityItemNames.TAB_ORDER]
                            ? loginData.SecurityItems[securityItemNames.TAB_ORDER]
                            : '',
                    },
                    iceServers: loginData.iceServers,
                    possibleMacros: loginData.PossibleMacros,
                    departments: loginData.departments ? loginData.departments : [],
                    activeStudies: loginData.activeStudies,
                    activeProfile:
                        activeStudy === undefined || activeStudy === '' ? PROFILE_HOME : activeStudy.studyName,
                    activeUserId: activeUserId,
                    termsHTML: termsHTML,
                    privacyHTML: privacyHTML,
                    extraLinks: loginData.extraLinks,
                    possibleStudies: loginData.possibleStudies ? loginData.possibleStudies : [],
                    // addConsignationsToAsk: loginData.addConsignationsToAsk ? loginData.addConsignationsToAsk : [],
                    // addConsignationsToAsk: getAddConsignationToAsk(
                    //     loginData.sessionId,
                    //     loginData.addConsignationsToAsk,
                    //     hashParams.addPlannedStudy,
                    // ),
                    addConsignationsToAsk: addConsignationResponse,
                    encounterId: loginData.encounterId,
                    encounterTitle: loginData.encounterTitle,
                    lastPatOpened: loginData.lastPatOpened ? loginData.lastPatOpened : [],
                    anmeldeContexts: loginData.anmeldeContexts ? loginData.anmeldeContexts : [],
                });

                /* set SecurityItems for a study */
                if (activeStudy !== undefined && activeStudy !== '') {
                    setActiveStudyName(activeStudy.studyName);
                }

                if (true || loginData.consignation === 'patFinished') {
                    getAndSetPersonalData(loginData.sessionId, activeUserId, loginData.practitionerRoleId);

                    const photoRes = await getPatientProfilePictureWL(loginData.sessionId, activeUserId);

                    if (photoRes.data.entry) {
                        tmdispatch({
                            type: 'SETPERSONALDATA_PROFILEPHOTO',
                            personalData: {
                                profilePhoto: photoRes.data.entry[0].resource.photo
                                    ? photoRes.data.entry[0].resource.photo[0].data
                                    : '',
                            },
                        });
                    }
                }

                tmdispatch({
                    type: 'SETWITHLOGIN',
                    withLogin: true,
                });

                setConsignation(loginData.consignation);
                loginData.consignation === 'not given' ? setNotGiven(true) : setNotGiven(false);

                if (loginData.consignation === 'doubleOptInMissing' && !loginData.forceNewPWD) {
                    if (loginData.doubleOptInViaLink && loginData.doubleOptInViaLink === 'true') {
                        showDoubleOptInViaLinkForm(true);
                    } else {
                        showDoubleOptInForm(true);
                    }
                } else if (loginData.forceNewPWD) {
                    if (loginData.consignation === 'doubleOptInMissing') {
                        /* wenn beides kommt, muss zuerst forceNewPWD ziehen,
                        showDoubleOptInForm(true); wird dann in handlePWChange() gemacht*/
                        setDoubleOptInMissingAfterPWDChange(true);
                    }
                    setInitPassword(true);
                } else if (loginData.consignation !== 'patFinished' && loginData.consignation !== 'given') {
                    showConsignationForm(true);
                } else if (loginData.consignation === 'given') {
                    showConsignationWaitForm(true);
                } else if (loginData.consignation === 'waitForLetter') {
                    showConsignationWaitForLetterForm(true);
                } else if (
                    (loginData.addConsignationsToAsk && loginData.addConsignationsToAsk.length > 0) ||
                    addPlannedStudyUrlParam
                ) {
                    showConsignationAddForm(true);
                } else if (loginData.practitionerRoleId) {
                    navigate(Paths.PATLIST);
                } else {
                    navigate(Paths.DOKUMENTE);
                }
            }
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>, username: string, password: string) => {
        event.preventDefault();

        const authorizeData: IAuthorizeAsync = {
            username: username,
            password: password,
        };
        loginHandling(authorizeData);
    };

    const handlePWChange = (newPwd: string) => {
        setInitPassword(false);
        setPassword(newPwd);
        if (consig !== 'patFinished') {
            if (doubleOptInMissingAfterPWDChange) {
                showDoubleOptInForm(true);
                setDoubleOptInMissingAfterPWDChange(false);
            } else {
                showConsignationForm(true);
            }
        } else {
            navigate(Paths.DOKUMENTE);
        }
    };

    const handleConsigDenial = () => {
        setNotGiven(true);
        showConsignationForm(false);
    };

    const handleConsigAddDenial = () => {
        showConsignationAddForm(false);
        navigate(Paths.DOKUMENTE);
    };

    const handleHideConsignationWaitModal = () => {
        showConsignationWaitForm(false);
        navigate(Paths.DOKUMENTE);
    };

    const handleHideConsignationWaitForLetterModal = () => {
        showConsignationWaitForLetterForm(false);
        handleLogout();
    };

    const handleHideDoDoubleOptInModal = () => {
        showDoubleOptInForm(false);
        handleLogout();
    };

    const handleHideDoDoubleOptInViaLinkModal = () => {
        showDoubleOptInViaLinkForm(false);
        // handleLogout();
    };

    const handleSubmitDoubleOptInModal = (code: string) => {
        showDoubleOptInForm(false);

        const authorizeData: IAuthorizeAsync = {
            username: username,
            password: password,
            doubleOptInCode: code,
        };
        loginHandling(authorizeData);
    };

    const LoginButton = () => {
        if (showLoginButtonLoadingSpinner) {
            let calcButtonWidth = '35px';
            const lottieSize = 24;
            calcButtonWidth = calcButtonPaddingSize(loginButtonRef, lottieSize);

            return (
                <StyledButton
                    ref={loginButtonRef}
                    paddingLeft={calcButtonWidth}
                    paddingRight={calcButtonWidth}
                    variant="primary"
                    type="submit"
                >
                    <LottieButtonLoadingSpinner size={lottieSize} />
                </StyledButton>
            );
        } else {
            return (
                <StyledButton ref={loginButtonRef} variant="primary" type="submit">
                    {t('LOGIN', 'Anmelden')}
                </StyledButton>
            );
        }
    };

    const handleCreateNewAccountButtonClick = () => {
        resetPersonalData();
        navigate(Paths.NEUER_ACCOUNT);
    };

    return (
        <div>
            <Row>
                <Col style={{ display: 'flex' }}>
                    <StyledProfileCard>
                        <Card.Body>
                            <StyledMainCardTitle>{t('LOGIN', 'Ihr persönliches Portal')} </StyledMainCardTitle>
                            <Form
                                className="m-3"
                                onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
                                    handleSubmit(event, username, password)
                                }
                            >
                                <Row>
                                    <Col>
                                        <Form.Group controlId="loginUsername">
                                            <StyledFormLabelForInput>
                                                {t('LOGIN', 'Benutzername')}
                                            </StyledFormLabelForInput>
                                            <Form.Control
                                                autoFocus
                                                type="text"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="loginPwd">
                                            <StyledFormLabelForInput>
                                                {/* {t('LOGIN', 'Passwort', { name: 'Test' })} */}
                                                {t('LOGIN', 'Passwort')}
                                            </StyledFormLabelForInput>
                                            <Form.Control
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <RightDiv>
                                    <LoginButton />
                                </RightDiv>
                                <Row>
                                    <div style={{ position: 'absolute', right: '18px' }}>
                                        <Button
                                            variant="link"
                                            style={{ fontSize: '0.85rem' }}
                                            onClick={() => navigate(Paths.RESET_PASSWORD)}
                                        >
                                            {t('LOGIN', 'Zugangsdaten vergessen?')}
                                        </Button>
                                    </div>
                                </Row>
                            </Form>
                        </Card.Body>
                        {apstate.allowAccountCreateDirect ? (
                            <Card.Footer>
                                <div style={{ float: 'right' }}>
                                    <StyledButton
                                        variant="secondary"
                                        onClick={() => handleCreateNewAccountButtonClick()}
                                    >
                                        Neuen Account erstellen
                                    </StyledButton>
                                </div>
                            </Card.Footer>
                        ) : null}
                    </StyledProfileCard>
                </Col>
            </Row>
            {initPassword ? (
                <div>
                    <InitialPassword onchange={(e) => handlePWChange(e)} oncancel={() => setInitPassword(false)} />
                </div>
            ) : (
                <></>
            )}
            {consignationForm ? (
                <div>
                    <Consignation
                        ondenial={() => handleConsigDenial()}
                        oncancel={() => showConsignationForm(false)}
                        notgiven={notGiven}
                    />
                </div>
            ) : (
                <></>
            )}
            {consignationAddForm ? (
                <div>
                    <ConsignationAdd
                        ondenial={() => handleConsigAddDenial()}
                        oncancel={() => showConsignationAddForm(false)}
                    />
                </div>
            ) : (
                <></>
            )}
            {consignationWaitForm ? (
                <div>
                    <ConsignationWaitModal
                        show={consignationWaitForm}
                        onHide={() => handleHideConsignationWaitModal()}
                    />
                </div>
            ) : (
                <></>
            )}
            {consignationWaitForLetterForm ? (
                <div>
                    <ConsignationWaitForLetterModal
                        show={consignationWaitForLetterForm}
                        onHide={() => handleHideConsignationWaitForLetterModal()}
                    />
                </div>
            ) : (
                <></>
            )}
            {doubleOptInForm ? (
                <div>
                    <DoDoubleOptInModal
                        show={doubleOptInForm}
                        onHide={() => handleHideDoDoubleOptInModal()}
                        onSubmit={(e) => handleSubmitDoubleOptInModal(e)}
                        username={username}
                        password={password}
                    />
                </div>
            ) : (
                <></>
            )}
            {doubleOptInViaLinkForm ? (
                <div>
                    <DoDoubleOptInViaLinkModal
                        show={doubleOptInViaLinkForm}
                        onHide={() => handleHideDoDoubleOptInViaLinkModal()}
                    />
                </div>
            ) : (
                <></>
            )}
            <TwoFactorAuthModal
                show={twoFAModalShow}
                onHide={() => set2FAModalShow(false)}
                username={username}
                password={password}
            />
        </div>
    );
};

export default Login;
