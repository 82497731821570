import { useContext, useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BiMinusCircle, BiPlusCircle } from 'react-icons/bi';
import { BsChevronLeft, BsChevronRight, BsFillEraserFill, BsX } from 'react-icons/bs';
import { FaFileSignature } from 'react-icons/fa';
import { FiDownload } from 'react-icons/fi';
import { Document, Page, pdfjs } from 'react-pdf';
import AlertContext, { AlertOnHide, AlertType } from '../../../context/alertContext';
import { ISigData } from '../../../context/sigpadContext';
import useScreenResolution from '../../../hooks/useScreenResolution';
import { addSignatureToPDF } from '../../../services/RestServices';
import { showAlertMessage } from '../../../utils/alertHandling';
import { ISignPosData } from '../../../utils/documentUtils';
import StyledButton from '../../Buttons/StyledButton';
import { EmbeddedPDFContainer, EmbeddedPDFContent, EmbeddedPDFFooter } from '../../Div/StyledDiv';
import { CenterRow } from '../../Rows/StyledRow';
import SignDocumentModal, { ISignDocumentModalShow } from '../SignDocumentModal/SignDocumentModal';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

export interface IEmbeddedPDFShow {
    show: boolean;
    modalTitle?: string;
    pdfData: string;
    isSignable?: boolean;
    isSigned?: boolean;
    diagnosticReportData?: any;
    signPosData?: ISignPosData[];
    signDone?(): void;
}

export interface IEmbeddedPDF extends IEmbeddedPDFShow {
    onHide(): void;
    showButtons: boolean;
    showCloseButton: boolean;
    showDownloadButton: boolean;
    height?: string;
}

const EmbeddedPDF = (props: IEmbeddedPDF) => {
    const screenSize = useScreenResolution();
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const [initScaleDone, setInitScaleDone] = useState(false);
    const [isHorizontalScrollable, setIsHorizontalScrollable] = useState(true);

    const [docIsSigned, setDocIsSigned] = useState(false);
    const [signModalShow, setSignModalShow] = useState<ISignDocumentModalShow>({
        show: false,
    });

    const [pdfDocAfterSign, setPdfDocAfterSign] = useState<any>();

    const [clickedClear, setClickedClear] = useState(false);
    const [clickedSign, setClickedSign] = useState(false);
    const [signPos, setSignPos] = useState<ISignPosData>();

    const { alertdispatch } = useContext(AlertContext);

    const pdfPage = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (screenSize.width > 0 && !initScaleDone) {
            if (screenSize.width < 768) {
                setIsHorizontalScrollable(true);
                setScale(0.7);
            } else if (screenSize.width < 821) {
                setIsHorizontalScrollable(true);
                setScale(1.0);
            }
            setInitScaleDone(true);
        }

        if (props.isSignable && !isWithModalMsg()) {
            setSignModalShow({
                show: true,
            });
        }
        if (pdfPage.current) {
            const resizeObserver = new ResizeObserver(() => {
                getSignRectPos(pageNumber);
            });
            resizeObserver.observe(pdfPage.current);
            return () => resizeObserver.disconnect();
        }
    }, [pageNumber, screenSize.width]);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const onPageLoadSuccess = (page) => {
        const parentDiv = document.querySelector('#embeddedPDFContainer');

        if (parentDiv) {
            const pageScale = parentDiv.clientHeight / page.originalHeight;
            if (scale !== pageScale) {
                setScale(pageScale);
            }
        }

        const scrollElement = document.querySelector('#embeddedPdfInModalBody');
        if (scrollElement) {
            scrollElement.scrollLeft = (scrollElement.scrollWidth - scrollElement.clientWidth) / 2;
        }
    };

    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }

    function previousPage(e: React.MouseEvent<HTMLButtonElement>) {
        if (e) {
            e.currentTarget.blur();
        }
        changePage(-1);
    }

    function nextPage(e: React.MouseEvent<HTMLButtonElement>) {
        if (e) {
            e.currentTarget.blur();
        }
        changePage(1);
    }

    const zoomOut = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        const newScale = scale - 0.1;
        if (newScale > 0.3) {
            //avoid too small zoom
            setScale(newScale);
        }
    };

    const zoomIn = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setScale(scale + 0.1);
    };

    const handleClose = () => {
        setScale(1.0);
        props.onHide();
    };

    const handleDownload = () => {
        /* This would be the simplest method, but did not work on Safari Mobile*/

        // const link = document.createElement('a');
        // link.href = props.pdfData;
        // link.download = 'document.pdf';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);

        /* more complicated method, but work on all browsers */
        if (props.pdfData) {
            const attachmentData = props.pdfData;
            const attachmentName = 'Test.pdf';
            let contentType = 'application/pdf';

            const base64WithoutContentType = attachmentData.replace(/^data:application\/pdf;base64,/, '');
            const binary = atob(base64WithoutContentType.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const linkElement = document.createElement('a');
            try {
                let hrefUrl = '';
                let blob: any = '';
                contentType = 'application/octet-stream';
                blob = new Blob([view], { type: contentType });
                hrefUrl = window.URL.createObjectURL(blob);
                linkElement.setAttribute('href', hrefUrl);
                linkElement.setAttribute('target', '_blank');
                linkElement.setAttribute('download', attachmentName);
                const clickEvent = new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: false,
                });
                linkElement.dispatchEvent(clickEvent);
            } catch (ex) {}
        }
    };

    const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setClickedClear(true);
    };

    const handleSign = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setClickedSign(true);
    };

    const onLoadError = (error: string) => {
        console.log('ract-pdf onLoadError: ' + error);
    };

    const convertPDFData = (pdfData: string) => {
        // const pdfDataConverted = pdfData;
        // if (pdfData.startsWith('data:application/pdf;base64,')) {
        //     pdfDataConverted = pdfDataConverted.substring('data:application/pdf;base64,'.length);
        // }
        return pdfData;
    };

    const showSignModal = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) {
            e.currentTarget.blur();
        }
        setSignModalShow({
            show: true,
        });
    };

    const onSignModalSigned = async (sigData: ISigData) => {
        setSignModalShow({ show: false });
        if (sigData && sigData.pictureData.length > 0 && props.diagnosticReportData) {
            const response = await addSignatureToPDF(sigData, props.diagnosticReportData);
            if (response.success !== 'true') {
                showAlertMessage({
                    alertTitle: 'PDF signieren',
                    alertTxt: 'Es ist ein Fehler bei der Signierung des PDFs aufgetreten.',
                    alertType: AlertType.error,
                    onHide: AlertOnHide.onlyClose,
                    alertdispatch: alertdispatch,
                });
            } else {
                setDocIsSigned(true);
                if (response.pdfURF) {
                    setPdfDocAfterSign(response.pdfURF);
                }
                if (props.signDone) {
                    props.signDone();
                }
            }
        }
    };

    const onSignModalCancel = () => {
        setSignModalShow({ show: false });
    };

    const showPDFData = () => {
        if (docIsSigned && pdfDocAfterSign) {
            return pdfDocAfterSign;
        } else {
            return props.pdfData;
        }
    };

    const isPageSignable = () => {
        if (docIsSigned) {
            return false;
        }
        if (props.signPosData) {
            for (const t of props.signPosData) {
                if (t.page === pageNumber) {
                    return true;
                }
            }
        }
        return false;
    };

    const isWithModalMsg = () => {
        if (docIsSigned) {
            return false;
        }
        if (props.signPosData) {
            for (const t of props.signPosData) {
                if (t.modalMsg) {
                    return true;
                }
            }
        }
        return false;
    };

    const getModalMsg = () => {
        if (docIsSigned) {
            return null;
        }
        if (props.signPosData) {
            for (const t of props.signPosData) {
                if (t.page === pageNumber) {
                    if (t.modalMsg) {
                        return t.modalMsg;
                    }
                }
            }
        }
        return null;
    };

    const transformDim = (dim: any): number => {
        if (typeof dim == 'string') {
            if (dim.endsWith('cm')) {
                return (parseFloat(dim.replace('cm', '')) / 2.54) * 72;
            }
            if (dim.endsWith('mm')) {
                return (parseFloat(dim.replace('mm', '')) / 25.4) * 72;
            }
            return parseFloat(dim);
        }
        if (!isNaN(dim)) {
            return dim;
        }
        return NaN;
    };

    const getSignRectPos = (pageNumber) => {
        if (!props.signPosData) {
            return;
        }
        // const pdfPage = document.body.querySelector('div.react-pdf__Page');

        if (!pdfPage) {
            return;
        }

        const scrollTop = pdfPage.current?.parentElement?.parentElement?.scrollTop
            ? pdfPage.current?.parentElement?.parentElement?.scrollTop
            : 0;
        const d0x = pdfPage?.current?.offsetLeft ? pdfPage?.current?.offsetLeft : 0;
        const d0y = pdfPage?.current?.offsetTop ? pdfPage?.current?.offsetTop : 0;
        const d1x = d0x + (pdfPage?.current?.offsetWidth ? pdfPage?.current?.offsetWidth : 0);
        const d1y = d0y + (pdfPage?.current?.offsetHeight ? d0y + pdfPage?.current?.offsetHeight : 0);
        /*
         * Angenommen wir ein DIN A4
         * in Koordinagen der SignPos: (Achtung: Rechteck in signPos ist relativ zur unteren Blattkante gemeint / so wie es in PDFs ueblich ist)
         * p1y = 0
         * p0y = 841.8897637795275624 (Hohe des A4 Blattes in Punkte also 29.7 cm / 2.54 * 72)
         * p0x = 0
         * p1x = 595.2755905511811048 (Breite des A4 Blattes in Punkte als 21 cm / 2.54 * 72)
         *
         * rx = linkes Eck des Signatur Rechteckes = ax * left + bx
         * fuer 0 gilt als ax * p0x + bx = d0x
         * fuer volle breite gilt ax * p1x + bx = d1x
         *
         * ry = oberes Eck des Signatur Rechteckes = ay * top + by
         * also 0 * ay + by = d1y
         * p1y * ay + by = d0y
         *
         * ax und ay muessen gleicht sein (ausser das ay negativ ist, also ax = -ay)
         *
         * => bx = d0x;
         * => by = d1y;
         * => ay = (d0y - by) / p1y;
         * => ax = -ay
         */
        const p0x = 0;
        const p1x = 595.276;
        const p1y = 0;
        const p0y = 841.89;
        const bx = d0x;
        const by = d1y;
        const ay = (d0y - by) / p0y;
        const ax = -ay;

        for (const t of props.signPosData) {
            if (t.page === pageNumber) {
                const transformedSignPosData: ISignPosData = {
                    top: ay * transformDim(t.top) + by,
                    left: ax * transformDim(t.left) + bx,
                    width: transformDim(t.width) * ax,
                    height: transformDim(t.height) * ax,
                    page: pageNumber,
                };
                // return transformedSignPosData;
                setSignPos(transformedSignPosData);
            }
        }

        // return undefined;
    };

    if (props.show) {
        return (
            <EmbeddedPDFContainer
                id="embeddedPDFContainer"
                overflowX={isHorizontalScrollable ? 'scroll' : 'hidden'}
                height={props.height ? props.height : undefined}
            >
                {/* <EmbeddedPDFHeader>
                    <div style={{ display: 'inline' }}>
                        <div style={{ float: 'right' }}>
                            <StyledButton
                                style={{ marginRight: '5px' }}
                                variant="secondary"
                                onClick={() => props.onHide()}
                            >
                                <BsX size={20} />
                            </StyledButton>
                        </div>
                    </div>
                </EmbeddedPDFHeader> */}
                <EmbeddedPDFContent>
                    <div ref={pdfPage} style={{ boxShadow: '0 30px 40px 0 rgb(16 36 94 / 20%)' }}>
                        <Document
                            file={convertPDFData(showPDFData())}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={onLoadError}
                        >
                            <Page
                                pageNumber={pageNumber}
                                onLoadSuccess={(page) => onPageLoadSuccess(page)}
                                scale={scale}
                            />
                            {isPageSignable() && (
                                <SignDocumentModal
                                    show={signModalShow.show}
                                    embedded={getModalMsg() ? false : true}
                                    onSign={onSignModalSigned}
                                    onCancel={onSignModalCancel}
                                    clickClear={clickedClear}
                                    clickSign={clickedSign}
                                    onClickClear={() => setClickedClear(false)}
                                    signPosData={signPos}
                                    title={getModalMsg() !== null ? getModalMsg() : 'Signieren'}
                                />
                            )}
                        </Document>
                    </div>
                </EmbeddedPDFContent>
                {props.showButtons && (
                    <EmbeddedPDFFooter>
                        <CenterRow>
                            <OverlayTrigger
                                placement="top"
                                transition={false}
                                overlay={<Tooltip id={`embeddedpdf-prevPage-tooltip`}>Vorherige Seite</Tooltip>}
                            >
                                <StyledButton
                                    variant="secondary"
                                    style={{ borderStyle: 'none' }}
                                    disabled={pageNumber <= 1}
                                    onClick={(e) => previousPage(e)}
                                >
                                    <BsChevronLeft />
                                </StyledButton>
                            </OverlayTrigger>
                            <div style={{ display: 'table', marginLeft: '10px', marginRight: '10px' }}>
                                <div
                                    style={{
                                        display: 'table-cell',
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    Seite {pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}
                                </div>
                            </div>
                            <OverlayTrigger
                                placement="top"
                                transition={false}
                                overlay={<Tooltip id={`embeddedpdf-nextPage-tooltip`}>Nächste Seite</Tooltip>}
                            >
                                <StyledButton
                                    variant="secondary"
                                    style={{ borderStyle: 'none' }}
                                    disabled={pageNumber >= numPages}
                                    onClick={(e) => nextPage(e)}
                                >
                                    <BsChevronRight />
                                </StyledButton>
                            </OverlayTrigger>
                            <div>
                                <OverlayTrigger
                                    placement="top"
                                    transition={false}
                                    overlay={<Tooltip id={`embeddedpdf-zoomOut-tooltip`}>Verkleinern</Tooltip>}
                                >
                                    <StyledButton
                                        style={{
                                            minWidth: '50px',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                            borderStyle: 'none',
                                        }}
                                        variant="secondary"
                                        onClick={(e) => zoomOut(e)}
                                    >
                                        <BiMinusCircle size={20} />
                                    </StyledButton>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    transition={false}
                                    overlay={<Tooltip id={`embeddedpdf-zoomIn-tooltip`}>Vergrößern</Tooltip>}
                                >
                                    <StyledButton
                                        variant="secondary"
                                        style={{
                                            minWidth: '50px',
                                            marginLeft: '5px',
                                            marginRight: '5px',
                                            borderStyle: 'none',
                                        }}
                                        onClick={(e) => zoomIn(e)}
                                    >
                                        <BiPlusCircle size={20} />
                                    </StyledButton>
                                </OverlayTrigger>
                                {props.showDownloadButton && (
                                    <OverlayTrigger
                                        placement="top"
                                        transition={false}
                                        overlay={
                                            <Tooltip id={`embeddedpdf-download-tooltip`}>
                                                Download / Öffnen mit...
                                            </Tooltip>
                                        }
                                    >
                                        <StyledButton
                                            style={{
                                                minWidth: '50px',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                                borderStyle: 'none',
                                            }}
                                            variant="secondary"
                                            onClick={() => handleDownload()}
                                        >
                                            <FiDownload size={20} />
                                        </StyledButton>
                                    </OverlayTrigger>
                                )}
                                {!props.isSigned && !docIsSigned && props.isSignable && (
                                    <OverlayTrigger
                                        placement="top"
                                        transition={false}
                                        overlay={
                                            <Tooltip id={`embeddedpdf-signClear-tooltip`}>Signierung löschen</Tooltip>
                                        }
                                    >
                                        <StyledButton
                                            style={{
                                                minWidth: '50px',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                                borderStyle: 'none',
                                            }}
                                            disabled={isPageSignable() ? false : true}
                                            variant="secondary"
                                            onClick={(e) => handleClear(e)}
                                        >
                                            <BsFillEraserFill size={20} />
                                        </StyledButton>
                                    </OverlayTrigger>
                                )}

                                {props.isSignable && (
                                    <OverlayTrigger
                                        placement="top"
                                        transition={false}
                                        overlay={
                                            <Tooltip id={`embeddedpdf-signStatus-tooltip`}>
                                                {docIsSigned || props.isSigned ? 'Signaturstatus' : 'Signieren'}
                                            </Tooltip>
                                        }
                                    >
                                        <StyledButton
                                            style={{
                                                minWidth: '50px',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                                borderStyle: 'none',
                                            }}
                                            disabled={isPageSignable() ? false : true}
                                            variant="secondary"
                                            onClick={(e) => (isWithModalMsg() ? showSignModal(e) : handleSign(e))}
                                        >
                                            {docIsSigned || props.isSigned ? (
                                                'ist signiert'
                                            ) : (
                                                <FaFileSignature size={20} />
                                            )}
                                        </StyledButton>
                                    </OverlayTrigger>
                                )}

                                {props.showCloseButton && (
                                    <OverlayTrigger
                                        placement="top"
                                        transition={false}
                                        overlay={<Tooltip id={`embeddedpdf-close-tooltip`}>Schließen</Tooltip>}
                                    >
                                        <StyledButton
                                            style={{
                                                minWidth: '50px',
                                                marginLeft: '5px',
                                                marginRight: '5px',
                                                borderStyle: 'none',
                                            }}
                                            variant="secondary"
                                            onClick={() => handleClose()}
                                        >
                                            <BsX size={20} />
                                        </StyledButton>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </CenterRow>
                    </EmbeddedPDFFooter>
                )}
            </EmbeddedPDFContainer>
        );
    } else {
        return null;
    }
};

export default EmbeddedPDF;
