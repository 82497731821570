import { useCallback, useContext } from 'react';
import AppPropsContext from '../context/appPropsContext';
import LoginContext, { ILanguage, ILanguageData } from '../context/loginContext';
import { languageElements } from '../languages/languageMap';

export const useLanguage = () => {
    const { state, dispatch } = useContext(LoginContext);
    const { apstate } = useContext(AppPropsContext);

    /**
     * get the available languages from the app properties 'availableLanguages'
     * @returns an array of available languages with id and name (e.g. [{id: 'de', name: 'Deutsch'}, {id: 'en', name: 'Englisch'}]), default is 'de'
     */
    const getAvailableLanguages = useCallback(() => {
        let languages: string[] = [];
        if (apstate.availableLanguages) {
            languages = apstate.availableLanguages.split(',');
        } else {
            languages = ['de'];
        }

        const availableLanguages: ILanguage[] = [];
        if (Array.isArray(languages) && languages.length) {
            availableLanguages.push({
                id: 'de',
                name: 'Deutsch',
            });
            languages.forEach((lang: string) => {
                if ('en' === lang) {
                    availableLanguages.push({
                        id: 'en',
                        name: 'Englisch',
                    });
                }
            });
        }

        return availableLanguages;
    }, [apstate.availableLanguages]);

    /**
     * get the active language from state
     */
    const getActiveLanguage = useCallback((): ILanguageData | undefined => {
        return state.activeLanguage;
    }, [state.activeLanguage]);

    /**
     * set the selected language as active language
     */
    const setActiveLanguage = useCallback(
        (props: ILanguage) => {
            (window as any).app.language = props.id;
            dispatch({
                type: 'SETACTIVELANGUAGE',
                activeLanguage: {
                    language: props,
                    changedFromUser: true,
                },
            });
        },
        [state.activeLanguage],
    );

    /**
     * get the language data with the given id from the available languages
     *
     * @param language the language id
     * @returns the language data with id and name (e.g. {id: 'de', name: 'Deutsch'}), default is 'de'
     */
    const getLanguageDataWithId = (language: string) => {
        const availableLanguages = getAvailableLanguages();
        for (const lang of availableLanguages) {
            if (lang.id === language) {
                return lang;
            }
        }
        return { id: 'de', name: 'Deutsch' };
    };

    /**
     * get the system language from the browser
     */
    const getSystemLanguage = useCallback(() => {
        return Intl.DateTimeFormat().resolvedOptions().locale;
    }, [state.activeLanguage]);

    /**
     * get the system language data
     */
    const getSystemLanguageData = useCallback(() => {
        const systemLanguage = getSystemLanguage();
        const sysLanguage = getLanguageDataWithId(systemLanguage);
        return sysLanguage;
    }, [state.activeLanguage]);

    /**
     * set the system language as active language (only if available), default is 'de'
     */
    const setSystemLanguage = useCallback(() => {
        const sysLanguage = getSystemLanguageData();

        (window as any).app.language = sysLanguage.id;
        dispatch({
            type: 'SETACTIVELANGUAGE',
            activeLanguage: {
                language: sysLanguage,
                changedFromUser: false,
            },
        });
    }, [state.activeLanguage]);

    /**
     * check if the language was changed from the user
     */
    const isLanguageChangedFromUser = useCallback(() => {
        return state.activeLanguage.changedFromUser;
    }, [state.activeLanguage]);

    const lookupTranslation = (module: string, language: string, refText: string) => {
        const moduleData = languageElements[module];
        if (!moduleData) {
            return undefined;
        }
        const languageData = moduleData[language];
        if (!languageData) {
            return undefined;
        }
        return languageData[refText];
    };

    /**
     * translate the given reference text with the given module and language
     * e.g. {t('MODULE', 'refText')} or with parameter {t('MODULE', 'refText', { name: 'ParamValue' })}
     * @param module the module name (e.g. LOGIN)
     * @param refText the reference text to translate
     * @param param optional parameter object to replace in the translation text
     * @returns the translated text or the reference text if not found
     */
    const t = (module: string, refText: string, param?: any) => {
        let translationText = lookupTranslation(module, state.activeLanguage.language.id, refText);
        if (!translationText) {
            return refText;
        }
        if (param) {
            for (const key in param) {
                translationText = translationText.replace(`{{${key}}}`, param[key]);
            }
        }
        return translationText;
    };

    return {
        getAvailableLanguages,
        setActiveLanguage,
        getActiveLanguage,
        setSystemLanguage,
        isLanguageChangedFromUser,
        getSystemLanguageData,
        t,
    };
};
