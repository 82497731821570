import { useContext, useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { ThemeContext } from 'styled-components';
import { CategoryBadgeSelected } from '../../components/Badges/StyledBadge';
import { StyledColNoPadding } from '../../components/Cols/StyledCol';
import { createSelectComboboxOption, ISelectComboboxOption, SelectCombobox } from '../../components/Combobox/Combobox';
import { LeftDivMain } from '../../components/Div/StyledDiv';
import { StyledRowNoMargin } from '../../components/Rows/StyledRow';
import { StyledFormRowNoMargin } from '../../components/StyledForm/SyledForm';
import { ParagraphFontSize } from '../../components/StyledParagraph/StyledParagraph';
import { StyledMainHeader, StyledSubHeader } from '../../components/StyledText/StyledHeader';
import { IDepartmentReasons, IDepartments } from '../../context/loginContext';
import TerminierungContext, { IHints, IHintsAnswers } from '../../context/terminierungContext';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import AppointmentCategoryReasonHints from './AppointmentCategoryReasonHints';

interface IAppointmentCategoryReason {
    departments: IDepartments;
    hints?: IHints[];
    handleReasonClick(): void;
}

const AppointmentCategoryReason = (props: IAppointmentCategoryReason) => {
    const themeContext = useContext(ThemeContext);
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const [reasonSelected, setReasonSelected] = useState<ISelectComboboxOption | null>();
    const [reasonSelected1, setReasonSelected1] = useState<ISelectComboboxOption | null>();
    const [reasonMainCodeSelected, setReasonMainCodeSelected] = useState<string>();
    const [insuranceSelected, setInsuranceSelected] = useState<string>();
    const [appTypeSelected, setAppTypeSelected] = useState<string>();
    const [hints, setHints] = useState<IHints>();
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    const { t } = useLanguage();

    const VERSICHERUNG_VOR_ORT_LONG = 'vor Ort';
    const VERSICHERUNG_VOR_ORT_SHORT = 'O';

    const VERSICHERUNG_PER_VIDEO_LONG = 'per Video';
    const VERSICHERUNG_PER_VIDEO_SHORT = 'V';

    const VERSICHERUNG_PER_TELEFON_LONG = 'per Telefon';
    const VERSICHERUNG_PER_TELEFON_SHORT = 'T';

    const TERMINART_PRIVAT_LONG = 'Private Krankenversicherung (PKV)';
    const TERMINART_PRIVAT_SHORT = 'P';

    const TERMINART_GESETZLICH_LONG = 'Gesetzliche Krankenversicherung (GKV)';
    const TERMINART_GESETZLICH_SHORT = 'G';

    const TERMINART_BG_LONG = 'Berufsgenossenschaft (BG)';
    const TERMINART_BG_SHORT = 'B';

    const TERMINART_SELBSTZAHLER_LONG = 'Selbstzahler (SZ)';
    const TERMINART_SELBSTZAHLER_SHORT = 'S';

    const getShortVersicherungsart = (versicherungsartLong: string) => {
        let versicherungsArtShort = '';
        if (versicherungsartLong) {
            if (versicherungsartLong === TERMINART_PRIVAT_LONG) {
                versicherungsArtShort = TERMINART_PRIVAT_SHORT;
            } else if (versicherungsartLong === TERMINART_GESETZLICH_LONG) {
                versicherungsArtShort = TERMINART_GESETZLICH_SHORT;
            } else if (versicherungsartLong === TERMINART_BG_LONG) {
                versicherungsArtShort = TERMINART_BG_SHORT;
            } else if (versicherungsartLong === TERMINART_SELBSTZAHLER_LONG) {
                versicherungsArtShort = TERMINART_SELBSTZAHLER_SHORT;
            }
        }

        return versicherungsArtShort;
    };

    const getShortTerminart = (terminartLong: string) => {
        let terminartShort = '';
        if (terminartLong) {
            if (terminartLong === VERSICHERUNG_VOR_ORT_LONG) {
                terminartShort = VERSICHERUNG_VOR_ORT_SHORT;
            } else if (terminartLong === VERSICHERUNG_PER_VIDEO_LONG) {
                terminartShort = VERSICHERUNG_PER_VIDEO_SHORT;
            } else if (terminartLong === VERSICHERUNG_PER_TELEFON_LONG) {
                terminartShort = VERSICHERUNG_PER_TELEFON_SHORT;
            }
        }

        return terminartShort;
    };

    const getLongVersicherungsart = (versicherungsartShort: string) => {
        let versicherungsArtLong = '';
        if (versicherungsartShort) {
            if (versicherungsartShort === TERMINART_PRIVAT_SHORT) {
                versicherungsArtLong = TERMINART_PRIVAT_LONG;
            } else if (versicherungsartShort === TERMINART_GESETZLICH_SHORT) {
                versicherungsArtLong = TERMINART_GESETZLICH_LONG;
            }
        }

        return versicherungsArtLong;
    };

    const getLongTerminart = (terminartShort: string) => {
        let terminartLong = '';
        if (terminartShort) {
            if (terminartShort === VERSICHERUNG_VOR_ORT_SHORT) {
                terminartLong = VERSICHERUNG_VOR_ORT_LONG;
            } else if (terminartShort === VERSICHERUNG_PER_VIDEO_SHORT) {
                terminartLong = VERSICHERUNG_PER_VIDEO_LONG;
            } else if (terminartShort === VERSICHERUNG_PER_TELEFON_SHORT) {
                terminartLong = VERSICHERUNG_PER_TELEFON_LONG;
            }
        }

        return terminartLong;
    };

    let filteredReasonsLocal: IDepartmentReasons[] = [];

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/AppointmentCategoryReason', title: 'Besuchsgrund wählen' });
    }, []);

    useEffect(() => {
        if (
            reasonSelected &&
            reasonSelected.value.length > 0 &&
            insuranceSelected &&
            insuranceSelected.length > 0 &&
            appTypeSelected &&
            appTypeSelected.length > 0
        ) {
            const filterVersicherungsart = getShortVersicherungsart(insuranceSelected);
            const filterTerminart = getShortTerminart(appTypeSelected);

            const filteredReasons = props.departments.reasons.filter((reason) => {
                if (
                    reason.reasonName === reasonSelected.value &&
                    ((reason.pFilter && reason.pFilter.length > 0 && reason.pFilter.includes(filterVersicherungsart)) ||
                        reason.pFilter === '') &&
                    ((reason.aFilter && reason.aFilter.length > 0 && reason.aFilter.includes(filterTerminart)) ||
                        reason.aFilter === '')
                ) {
                    return true;
                }
            });
            let foundHint: IHints | undefined;
            if (filteredReasons) {
                tmdispatch({
                    type: 'SETFILTEREDREASONS',
                    filteredReasons: filteredReasons,
                });
                filteredReasonsLocal = filteredReasons;
                tmdispatch({
                    type: 'SELECTINSURANCE',
                    selectedInsurance: insuranceSelected,
                });
                tmdispatch({
                    type: 'SELECTAPPOINTMENTTYPE',
                    selectedAppointmentType: appTypeSelected,
                });

                /* check for possible hints questions */

                if (props.hints) {
                    let hints = props.hints;

                    /* filter for department */
                    const selectedDepartment = tmstate.selectedDepartment.name;
                    hints = props.hints.filter((hint) => {
                        if (
                            hint.department === undefined ||
                            (hint.department && hint.department === selectedDepartment)
                        ) {
                            return true;
                        }
                    });

                    /* filter for rest */
                    for (let i = 0; i < filteredReasons.length; i++) {
                        for (let j = 0; j < hints.length; j++) {
                            if (hints[j].reason === filteredReasons[i].reasonCode) {
                                let aFilterChecked = false;
                                let pFilterChecked = false;

                                const hintAFilter = hints[j].aFilter;
                                if (hintAFilter && hintAFilter.length > 0) {
                                    if (hintAFilter.includes(filterTerminart)) {
                                        aFilterChecked = true;
                                    }
                                } else {
                                    aFilterChecked = true;
                                }

                                const hintPFilter = hints[j].pFilter;
                                if (hintPFilter && hintPFilter.length > 0) {
                                    if (hintPFilter.includes(filterVersicherungsart)) {
                                        pFilterChecked = true;
                                    }
                                } else {
                                    pFilterChecked = true;
                                }

                                if (aFilterChecked && pFilterChecked) {
                                    foundHint = hints[j];
                                    break;
                                }
                            }
                        }

                        if (foundHint) {
                            break;
                        }
                    }
                }
            }

            if (foundHint) {
                setHints(foundHint);
            } else {
                handleReasonClick();
            }
        }
    }, [reasonSelected, insuranceSelected, appTypeSelected]);

    const handleReasonClick = () => {
        let selectedReasonId: string | null = null;
        for (const v of filteredReasonsLocal) {
            if (v.reasonName == reasonSelected?.value) {
                selectedReasonId = v.reasonId;
            }
            v.reasonId;
        }
        tmdispatch({
            type: 'SELECTREASON',
            selectedReason: {
                ...tmstate.selectedReason,
                reasonName: reasonSelected?.value,
                reasonId: selectedReasonId,
            },
        });
        props.handleReasonClick();
    };

    const onHintsSubmit = (answers: IHintsAnswers[]) => {
        if (answers) {
            tmdispatch({
                type: 'SELECTREASONHINTS',
                selectedReasonHints: answers,
            });
        }
        handleReasonClick();
    };

    const handleSetComboboxReason = (e: any) => {
        const reason = e.value;
        const filteredReason = props.departments.reasons.find((freason) => {
            if ((freason.reasonMainCode && freason.reasonMainCode === reason) || freason.reasonName == reason) {
                return true;
            }
        });

        if (filteredReason?.reasonMainCode) {
            const option = createSelectComboboxOption(filteredReason.reasonMainCode);
            setReasonMainCodeSelected(filteredReason.reasonMainCode);
            setReasonSelected1(option);
            setReasonSelected(null);
            setHints({
                reason: '',
            });
        } else {
            setReasonMainCodeSelected('');
            setReasonSelected1(e);
            setReasonSelected(e);
        }
    };

    const handleSetComboboxReasonSub = (e: any) => {
        const reason = e.value;
        if (reason.startsWith('Main|')) {
            setReasonMainCodeSelected(reason.split('|')[1]);
        } else {
            setReasonSelected(e);
        }
    };

    const handleSetComboboxInsurance = (e: any) => {
        setInsuranceSelected(e.target.value.toString());
    };

    const handleSetComboboxAppType = (e: any) => {
        setAppTypeSelected(e.target.value.toString());
    };

    const getVersicherungsartOptions = () => {
        if (props.departments.versicherungsArt && props.departments.versicherungsArt.length > 0) {
            return props.departments.versicherungsArt.map((versicherungsArt) => (
                <option key={versicherungsArt} value={versicherungsArt}>
                    {versicherungsArt}
                </option>
            ));
        } else {
            return (
                <>
                    <option key="gesetzlich" value={TERMINART_GESETZLICH_LONG}>
                        {TERMINART_GESETZLICH_LONG}
                    </option>
                    <option key="privat" value={TERMINART_PRIVAT_LONG}>
                        {TERMINART_PRIVAT_LONG}
                    </option>
                    <option key="selbstzahler" value={TERMINART_SELBSTZAHLER_LONG}>
                        {TERMINART_SELBSTZAHLER_LONG}
                    </option>
                    <option key="bg" value={TERMINART_BG_LONG}>
                        {TERMINART_BG_LONG}
                    </option>
                </>
            );
        }
    };

    const getVersicherungsartDefault = () => {
        if (props.departments.versicherungsArt && props.departments.versicherungsArt.length == 1) {
            // setInsuranceSelected(props.departments.versicherungsArt[0]);
            if (!insuranceSelected) {
                setInsuranceSelected(props.departments.versicherungsArt[0]);
            }
            return props.departments.versicherungsArt[0];
        }
        return '';
    };

    const getVersicherungsartDisabled = () => {
        if (props.departments.versicherungsArt && props.departments.versicherungsArt.length == 1) {
            return true;
        }
        return false;
    };

    const getKontaktartOptions = () => {
        if (props.departments.kontaktArt && props.departments.kontaktArt.length > 0) {
            return props.departments.kontaktArt.map((kontaktArt) => (
                <option key={kontaktArt} value={kontaktArt}>
                    {kontaktArt}
                </option>
            ));
        } else {
            return (
                <>
                    <option key="vorOrt" value={VERSICHERUNG_VOR_ORT_LONG}>
                        {VERSICHERUNG_VOR_ORT_LONG}
                    </option>
                    <option key="video" value={VERSICHERUNG_PER_VIDEO_LONG}>
                        {VERSICHERUNG_PER_VIDEO_LONG}
                    </option>
                    <option key="telefon" value={VERSICHERUNG_PER_TELEFON_LONG}>
                        {VERSICHERUNG_PER_TELEFON_LONG}
                    </option>
                </>
            );
        }
    };

    const getKontaktartDefault = () => {
        if (props.departments.kontaktArt && props.departments.kontaktArt.length == 1) {
            // setAppTypeSelected(props.departments.kontaktArt[0]);
            if (!appTypeSelected) {
                setAppTypeSelected(props.departments.kontaktArt[0]);
            }
            return props.departments.kontaktArt[0];
        }
        return '';
    };

    const getKontaktartDisabled = () => {
        if (props.departments.kontaktArt && props.departments.kontaktArt.length == 1) {
            return true;
        }
        return false;
    };

    const getBesuchsgrundOptions = (mainReason: string | null) => {
        let filterVersicherungsart = '';
        if (insuranceSelected) {
            filterVersicherungsart = getShortVersicherungsart(insuranceSelected);
        }
        let filterTerminart = '';
        if (appTypeSelected) {
            filterTerminart = getShortTerminart(appTypeSelected);
        }

        const usedReasons: string[] = [];
        const usedReasonMainCodes: string[] = [];
        const reasonsDone = {};
        const reasonsSelect: ISelectComboboxOption[] = [];
        props.departments.reasons.forEach((reason) => {
            if (mainReason) {
                if (!reason.reasonMainCode || reason.reasonMainCode !== mainReason) {
                    return null;
                } else {
                    if (reasonsDone[reason.reasonId]) {
                        return null;
                    }
                    if (
                        (reason.pFilter && !reason.pFilter.includes(filterVersicherungsart) && reason.pFilter !== '') ||
                        (reason.aFilter && !reason.aFilter.includes(filterTerminart) && reason.aFilter !== '')
                    ) {
                        return null;
                    }
                    reasonsDone[reason.reasonId] = true;
                    const name = reason.reasonName;
                    const optionSelect: ISelectComboboxOption = { value: reason.reasonName, label: name };
                    reasonsSelect.push(optionSelect);
                }
            } else {
                if (
                    (reason.pFilter && !reason.pFilter.includes(filterVersicherungsart) && reason.pFilter !== '') ||
                    (reason.aFilter && !reason.aFilter.includes(filterTerminart) && reason.aFilter !== '')
                ) {
                    return null;
                } else {
                    if (
                        (reason.reasonMainCode && usedReasonMainCodes.includes(reason.reasonMainCode)) ||
                        usedReasons.includes(reason.reasonId)
                    ) {
                        return null;
                    } else {
                        usedReasons.push(reason.reasonId);

                        let name = reason.reasonName;
                        let valueSub = reason.reasonName;
                        if (reason.reasonMainCode && reason.reasonMainCode.length > 0) {
                            usedReasonMainCodes.push(reason.reasonMainCode);
                            name = reason.reasonMainCode;
                            valueSub = name;
                        }
                        const optionSelect: ISelectComboboxOption = { value: valueSub, label: name };
                        reasonsSelect.push(optionSelect);
                    }
                }
            }
        });

        return reasonsSelect;
    };

    const getBesuchsgrundDefault = () => {
        if (
            props.departments.reasons &&
            props.departments.reasons.length == 1 &&
            !props.departments.reasons[0].reasonMainCode
        ) {
            // setAppTypeSelected(props.departments.kontaktArt[0]);
            if (!reasonSelected) {
                const option = createSelectComboboxOption(props.departments.reasons[0].reasonName);
                setReasonSelected(option);
            }
            return props.departments.reasons[0].reasonName;
        }
        return '';
    };

    const getBesuchsgrundDisabled = () => {
        if (
            props.departments.reasons &&
            props.departments.reasons.length == 1 &&
            !props.departments.reasons[0].reasonMainCode
        ) {
            return true;
        }
        return false;
    };

    const displayHints = () => {
        if (hints?.reason && hints?.reason.length > 0) {
            return <AppointmentCategoryReasonHints hints={hints} onHintsSubmit={onHintsSubmit} />;
        }
    };

    return (
        <LeftDivMain>
            <StyledMainHeader>{t('ANMELD', 'Besuchsgrund wählen')}</StyledMainHeader>
            <br />
            <div>
                <StyledSubHeader>
                    {t('ANMELD', 'Besuchsgrund')}
                    <CategoryBadgeSelected
                        backgroundColor={themeContext.mainColor}
                        color="white"
                        marginLeft="20px"
                        fontSize={ParagraphFontSize.Medium}
                    >
                        {props.departments.name}
                    </CategoryBadgeSelected>
                </StyledSubHeader>
            </div>

            <br />
            <StyledRowNoMargin>
                <StyledColNoPadding xs={12} sm={12} md={6}>
                    <StyledFormRowNoMargin>
                        <Form.Label>
                            <b>{t('ANMELD', 'Versichertentyp')}</b>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="insurance"
                            defaultValue={getVersicherungsartDefault()}
                            value={insuranceSelected}
                            disabled={getVersicherungsartDisabled()}
                            onChange={(e) => handleSetComboboxInsurance(e)}
                        >
                            <option disabled key="insuranceDefault" hidden value="">
                                {t('ANMELD', 'Wählen Sie den Versichertentyp')}
                            </option>
                            {getVersicherungsartOptions()}
                        </Form.Control>
                    </StyledFormRowNoMargin>
                </StyledColNoPadding>
            </StyledRowNoMargin>
            <StyledRowNoMargin>
                <StyledColNoPadding xs={12} sm={12} md={6}>
                    <br />
                    <StyledFormRowNoMargin>
                        <Form.Label>
                            <b>{t('ANMELD', 'Terminart')}</b>
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="apptype"
                            defaultValue={getKontaktartDefault()}
                            value={appTypeSelected}
                            disabled={getKontaktartDisabled()}
                            onChange={(e) => handleSetComboboxAppType(e)}
                        >
                            <option disabled key="appTypeDefault" hidden value="">
                                {t('ANMELD', 'Wählen Sie die Terminart')}
                            </option>
                            {getKontaktartOptions()}
                        </Form.Control>
                    </StyledFormRowNoMargin>
                    <br />
                </StyledColNoPadding>
            </StyledRowNoMargin>
            <StyledRowNoMargin>
                <StyledColNoPadding>
                    <Form.Label>
                        <b>{t('ANMELD', 'Besuchsgrund')}</b>
                    </Form.Label>
                    <StyledFormRowNoMargin>
                        <StyledColNoPadding xs={12} sm={12} md={6}>
                            {/* <Form.Control
                                as="select"
                                name="reason"
                                defaultValue={getBesuchsgrundDefault()}
                                value={reasonSelected1}
                                disabled={getBesuchsgrundDisabled()}
                                onChange={(e) => handleSetComboboxReason(e)}
                            >
                                <option disabled key="reasonDefault" hidden value="">
                                    {t('ANMELD', 'Wählen Sie den Besuchsgrund')}
                                </option>
                                {getBesuchsgrundOptions(null)}
                            </Form.Control> */}
                            <SelectCombobox
                                options={getBesuchsgrundOptions(null)}
                                defaultValue={getBesuchsgrundDefault()}
                                placeholder={t('ANMELD', 'Wählen Sie den Besuchsgrund')}
                                value={reasonSelected1}
                                disabled={getBesuchsgrundDisabled()}
                                isSearchable={true}
                                onChange={(e) => handleSetComboboxReason(e)}
                            />
                        </StyledColNoPadding>

                        <Col style={{ paddingLeft: '5px', paddingRight: '0px' }}>
                            {reasonMainCodeSelected && reasonMainCodeSelected.length > 0 && (
                                // <Form.Control
                                //     as="select"
                                //     name="reasonSub"
                                //     defaultValue=""
                                //     value={reasonSelected}
                                //     onChange={(e) => handleSetComboboxReasonSub(e)}
                                // >
                                //     <option disabled key="reasonSubDefault" hidden value="">
                                //         Wählen Sie die Untergruppe
                                //     </option>
                                //     {getBesuchsgrundOptions(reasonMainCodeSelected, false)}
                                // </Form.Control>
                                <SelectCombobox
                                    options={getBesuchsgrundOptions(reasonMainCodeSelected)}
                                    defaultValue=""
                                    placeholder="Wählen Sie die Untergruppe"
                                    value={reasonSelected}
                                    isSearchable={true}
                                    onChange={(e) => handleSetComboboxReasonSub(e)}
                                />
                            )}
                        </Col>
                    </StyledFormRowNoMargin>
                </StyledColNoPadding>
            </StyledRowNoMargin>
            {hints && displayHints()}
        </LeftDivMain>
    );
};

export default AppointmentCategoryReason;
