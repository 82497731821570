import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SelectButton from '../../components/Buttons/SelectButton';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext, { IAvailableTimeslot } from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useLanguage } from '../../hooks/useLanguage';
import { blockAppointment, unblockAppointment } from '../../services/RestServices';
import {
    setReplacedCareUnitForResource,
    setReplacedDepartmentForResource,
    setReplacedPractitionerForResource,
    setReplacedRoomForResource,
    setReplacedServiceTypeForResource,
    setReplacedStartEndForResource,
} from '../../utils/appointmentUtils';
import { getGermanDateFormatFromString } from '../../utils/pcpUtils';
import BlockingAppointmentModal from '../Modals/BlockingAppointmentModal/BlockingAppointmentModal';

interface Props {
    selectedDay: string;
}

export const isTimeslotDateSet = (date: any) => {
    /* the default init date is a date type with 'new Date(0)' */
    if (date) {
        if (typeof date.start === 'string' && typeof date.end === 'string') {
            return true;
        }
    }

    return false;
};

const TimeSlots = (props: Props) => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);
    const {
        getSelectedResource,
        setSelectedResource,
        setSelectedTimeslot,
        getAvailableTimeslots,
        getSelectedTimeslot,
        getMakroIdFromResourceServiceType,
    } = useAppointmentSelection();

    const { t } = useLanguage();

    interface IBlockingModalShow {
        show: boolean;
    }
    const [blockingModalShow, setBlockingModalShow] = useState<IBlockingModalShow>({
        show: false,
    });

    const getTime = (date: Date) => {
        return moment(date).format('HH:mm');
    };

    useEffect(() => {
        const resource_1 = getSelectedResource(1);
        if (resource_1) {
            unblockAppointment(resource_1);
        }
    }, []);

    const clickedTimeslot = async (timeslot: IAvailableTimeslot, supressDuration: boolean) => {
        // console.log('clicked timeslot: start: ', dateStart, ' end: ', dateEnd);
        const selectedTimeslot = {
            start: timeslot.timeslot.start,
            end: timeslot.timeslot.end,
            supressDuration: supressDuration,
        };

        // if (tmstate.workOnAppointmentNr > 1) {
        //     let updatedResource = setReplacedRoomForResource(tmstate.selectedResource_2, timeslot.replacedRoom);
        //     updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);

        //     tmdispatch({
        //         type: 'SELECTTIMESLOT_2',
        //         selectedTimeslot_2: selectedTimeslot,
        //     });
        //     tmdispatch({
        //         type: 'SETRESOURCE_2',
        //         selectedResource_2: updatedResource,
        //     });
        // } else {
        //     let updatedResource = setReplacedRoomForResource(tmstate.selectedResource, timeslot.replacedRoom);
        //     updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);

        //     tmdispatch({
        //         type: 'SELECTTIMESLOT',
        //         selectedTimeslot: selectedTimeslot,
        //     });
        //     tmdispatch({
        //         type: 'SETRESOURCE',
        //         selectedResource: updatedResource,
        //     });
        // }

        let updatedResource = setReplacedRoomForResource(
            getSelectedResource(tmstate.workOnAppointmentNr),
            timeslot.replacedRoom,
        );
        updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);
        updatedResource = setReplacedPractitionerForResource(updatedResource, timeslot.replacedPractitioner);
        updatedResource = setReplacedCareUnitForResource(updatedResource, timeslot.replacedCareUnit);
        updatedResource = setReplacedDepartmentForResource(updatedResource, timeslot.replacedDepartment);
        updatedResource = setReplacedServiceTypeForResource(updatedResource, timeslot.replacedServiceType);

        setSelectedResource(tmstate.workOnAppointmentNr, updatedResource);

        const resource = getSelectedResource(1);
        const blockResponse = await blockAppointment(resource);
        if (blockResponse.success === 'false') {
            // onNewAppointments(blockResponse.newApp, tmstate.workOnAppointmentNr, setSelectedTimeslot);
            // gotNewAppointment = true;
            setBlockingModalShow({
                show: true,
            });
            return;
        }

        setSelectedTimeslot(tmstate.workOnAppointmentNr, selectedTimeslot);

        /* Fruehester Termin -> get makroId from ServiceType and find resource from filteredResources */
        if (tmstate.selectedReason?.makroId !== undefined && tmstate.selectedReason.makroId === 0) {
            const makroId = getMakroIdFromResourceServiceType(updatedResource);

            if (makroId && makroId > 0) {
                const filteredSelectedReason = tmstate.filteredReasons.filter((e) => e.makroId === makroId);
                tmdispatch({
                    type: 'SELECTREASON',
                    selectedReason: filteredSelectedReason[0],
                });
            }
        }
    };

    const getTimeRange = (slot: IAvailableTimeslot, supressDuration: boolean) => {
        if (supressDuration) {
            return getTime(slot.timeslot.start);
        }
        return getTime(slot.timeslot.start) + ' - ' + getTime(slot.timeslot.end);
    };
    const displayAllTimeslots = () => {
        const availableTimeslots = getAvailableTimeslots(tmstate.workOnAppointmentNr);
        const selectedTimeslot = getSelectedTimeslot(tmstate.workOnAppointmentNr);
        if (availableTimeslots) {
            const allTimeslots = availableTimeslots.availableTimeslot.map((data: IAvailableTimeslot, index: number) => {
                if (data.replacedPractitioner?.display) {
                    return (
                        <Col key={index}>
                            <OverlayTrigger
                                key={index}
                                show={apstate.Appointment?.skipBehandlersuche === true ? false : undefined}
                                placement="bottom"
                                overlay={
                                    <Tooltip id="physicianTimeSlotTooltip">
                                        {data.replacedPractitioner?.display ? data.replacedPractitioner?.display : ''}
                                    </Tooltip>
                                }
                            >
                                <SelectButton
                                    className={['ml-3', 'mt-3']}
                                    selected={selectedTimeslot?.start === data.timeslot.start}
                                    height="80%"
                                    width="100%"
                                    variant="outline-primary"
                                    block="true"
                                    onClick={() => clickedTimeslot(data, availableTimeslots.supressDuration)}
                                >
                                    {getTimeRange(data, availableTimeslots.supressDuration)}
                                </SelectButton>
                            </OverlayTrigger>
                        </Col>
                    );
                } else {
                    return (
                        <SelectButton
                            className={['ml-3', 'mt-3']}
                            selected={selectedTimeslot?.start === data.timeslot.start}
                            height="80%"
                            width="100%"
                            variant="outline-primary"
                            block="true"
                            onClick={() => clickedTimeslot(data, availableTimeslots.supressDuration)}
                        >
                            {getTimeRange(data, availableTimeslots.supressDuration)}
                        </SelectButton>
                    );
                }
            });
            return allTimeslots;
        }

        return <></>;
    };

    // return <StyledButton>TimeSlot</StyledButton>;
    return (
        <>
            <div>
                <p>
                    {t('ANMELD', 'Verfügbare Zeiten für den')}
                    {': '}
                    {props.selectedDay === '' ? (
                        ''
                    ) : (
                        <strong>{getGermanDateFormatFromString(props.selectedDay)}</strong>
                    )}
                </p>
                <Row xs={1} sm={1} md={2} lg={3} xl={3}>
                    {displayAllTimeslots()}
                </Row>
            </div>
            <BlockingAppointmentModal
                show={blockingModalShow.show}
                onHide={() => setBlockingModalShow({ show: false })}
            />
        </>
    );
};

export default TimeSlots;
