export const languageElements = {
    BUTTONS: {
        en: {
            Weiter: 'Next',
            Abbrechen: 'Cancel',
            'Jetzt buchen': 'Book now',
        },
    },
    LOGIN: {
        en: {
            Anmelden: 'Login',
            Benutzername: 'Username',
            Passwort: 'Password',
            'Ihr persönliches Portal': 'Your personal portal access',
            'Die App für Ihr Handy': 'The app for your mobile phone',
            'Zugangsdaten vergessen?': 'Forgot your login details?',
            'Ihre Terminplanung ohne Account': 'Your appointment planning without an account',
            'Neuen Termin anfordern': 'Request a new appointment',
            'Standort wählen': 'Choose location',
        },
    },
    ANMELD: {
        de: {
            'Termin wählen': '{{appointmentName}} wählen',
        },
        en: {
            Fachrichtung: 'Specialization',
            'Fachrichtung wählen': 'Choose specialization',
            'Wählen Sie die gewünschte Fachrichtung aus': 'Select the desired specialization',
            'Besuchsgrund wählen': 'Choose reason for visit',
            Besuchsgrund: 'Reason for visit',
            Versichertentyp: 'Type of insurance',
            'Wählen Sie den Versichertentyp': 'Select the type of insurance',
            Terminart: 'Type of appointment',
            'Wählen Sie die Terminart': 'Select the type of appointment',
            'Wählen Sie den Besuchsgrund': 'Select the reason for your visit',
            Behandler: 'Physician',
            'Behandler wählen': 'Choose physician',
            'Termin wählen': 'Choose {{appointmentName}}',
            Termin: 'appointment',
            'Wählen Sie Tag und Uhrzeit für': 'Select day and time for',
            'Verfügbare Zeiten für den': 'Available times for',
            'Termin buchen': 'Book appointment',
            'Daten eingeben': 'Enter data',
            'Haben Sie bereits einen Account?': 'Already have an account?',
            'Hier anmelden': 'Log in here',
            'Account erstellen': 'Create account',
            'Personendaten eingeben': 'Enter personal data',
            'Termin bestätigen': 'Confirm appointment',
            'Prüfen Sie Ihre Angaben.': 'Check your details.',
            'Ihre persönlichen Daten': 'Your personal data',
            Bemerkung: 'Note',
            'Behandler und Termin wählen': 'Choose physician and appointment',
            'Mehr Termine anzeigen': 'Show more appointments',
            'Weniger Termine anzeigen': 'Show fewer appointments',
        },
    },
    PERSONALDATA: {
        en: {
            Vorname: 'First name',
            Nachname: 'Last name',
            Titel: 'Academic title',
            Geburtstag: 'Date of birth',
            Geschlecht: 'Gender',
            Telefonnummer: 'Phone number',
            'Mobil Telefonnummer': 'Mobile phone number',
            'Mobil-Telefonnummer': 'Mobile phone number',
            'E-Mail': 'Email',
            Postleitzahl: 'Zip code',
            Ort: 'City',
            Straße: 'Street',
            Hausnummer: 'House number',
            Land: 'Country',
            Versichertennummer: 'Insurance number',
            'Versichertennr.': 'Insurance number',
            Krankenkasse: 'Health insurance',
            Sprache: 'Language',
            'Bitte geben Sie Ihre Versichertennummer an.': 'Please enter your insurance number.',
            'Bitte geben Sie Ihre Krankenkassse an.': 'Please enter your health insurance.',
            'Bitte geben Sie Ihre Sprache an.': 'Please enter your language.',
            'Bitte geben Sie Ihren Vornamen an.': 'Please enter your first name.',
            'Bitte geben Sie Ihren Nachnamen an.': 'Please enter your last name.',
            'Bitte geben Sie Ihren Geburtstag an.': 'Please enter your date of birth.',
            'Bitte geben Sie Ihre E-Mail-Adresse an.': 'Please enter your email address.',
            'Ungültiges E-Mail-Format': 'Invalid email format',
            'Bitte geben Sie Ihre Telefonnummer an.': 'Please enter your phone number.',
            'Bitte geben Sie Ihr Geschlecht an.': 'Please enter your gender',
            'Bitte geben Sie Ihre Straße an.': 'Please enter your street.',
            'Bitte geben Sie Ihre Hausnummer an.': 'Please enter your house number.',
            'Bitte geben Sie Ihre Postleitzahl an.': 'Please enter your zip code.',
            'Bitte geben Sie Ihren Ort an.': 'Please enter your city.',
            'Bitte geben Sie Ihr Land an.': 'Please enter your country.',
            'Bitte geben Sie Ihre Mobil-Telefonnummer an.': 'Please enter your mobile phone number.',
            'Bitte geben Sie Ihren Titel an.': 'Please enter your academic title.',
            'Bitte geben Sie einen Benutzernamen an.': 'Please enter a username.',
            'Bitte geben Sie ein Passwort an.': 'Please enter a password.',
            'Gewünschte Studienteilnahmen': 'Desired study participations',
        },
    },
    COMMON: {
        en: {
            Impressum: 'Legal information',
            Datenschutz: 'Data privacy',
        },
    },
};
