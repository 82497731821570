import * as React from 'react';
import { Alert, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import { StyledModal } from '../StyledModal/StyledModal';

export interface ICancelConfirmModalShow {
    show: boolean;
}

interface ICancelConfirmModal extends ICancelConfirmModalShow {
    onHide: () => void;
    onSubmit: () => void;
}

const CancelConfirmModal = (props: ICancelConfirmModal) => {
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        {
            props.onSubmit();
        }
    };

    return (
        <StyledModal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Abbrechen</Modal.Title>
            </Modal.Header>
            <Form className="m-3" noValidate onSubmit={handleSubmit}>
                <Container fluid>
                    <div>
                        <Modal.Body>
                            <div>
                                <Alert variant="danger">
                                    <p>Möchten Sie wirklich abbrechen? Ihre eingegebenen Daten gehen dabei verloren.</p>
                                </Alert>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Container className="mt-4">
                                <Row>
                                    <StyledCol paddingLeft="0px" textAlign="left">
                                        <StyledButton variant="secondary" onClick={props.onHide}>
                                            Zurück
                                        </StyledButton>
                                    </StyledCol>
                                    <StyledCol paddingRight="0px" textAlign="right">
                                        <StyledButton type="submit">Abbrechen</StyledButton>
                                    </StyledCol>
                                </Row>
                            </Container>
                        </Modal.Footer>
                    </div>
                </Container>
            </Form>
        </StyledModal>
    );
};

export default CancelConfirmModal;
