import moment from 'moment';
import 'moment/locale/de';
import { useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
// import 'typeface-roboto';
import ReactGA from 'react-ga4';
import AppRoutes, { Paths } from './Routes';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import AlertModal from './components/Modals/AlertModal/AlertModal';
import AlertContext, { AlertOnHide, AlertType, initialAlertState } from './context/alertContext';
import { alertReducer } from './context/alertReducer';
import AppPropsContext, { InitialAppPropsState } from './context/appPropsContext';
import { appPropsReducer } from './context/appPropsReducer';
import CheckInContext, { initialCheckInState } from './context/checkInContext';
import { checkinReducer } from './context/checkInReducer';
import DokumenteContext, { initialDokumenteState } from './context/dokumenteContext';
import { dokumenteReducer } from './context/dokumenteReducer';
import ExecuteRuleContext, { initialExecuteRuleState } from './context/executeRuleContext';
import { executeRuleReducer } from './context/executeRuleReducer';
import IFrameContext, { initialIFrameState } from './context/iframeContext';
import { iframeReducer } from './context/iframeReducer';
import LoginContext, { initialLoginState } from './context/loginContext';
import { loginReducer } from './context/loginReducer';
import QuestionnaireContext, { initialQuestionnaireState } from './context/questionnaireContext';
import { questionnaireReducer } from './context/questionnaireReducer';
import { sigpadReducer } from './context/sigPadReducer';
import SigPadContext, { initialSigPadState } from './context/sigpadContext';
import TerminierungContext, { initialTerminierungState } from './context/terminierungContext';
import { terminierungReducer } from './context/terminierungReducer';
import ThemeContext, { initialThemeState } from './context/themeContext';
import { themeReducer } from './context/themeReducer';
import VideochatContext, { initialVideochatState } from './context/videochatContext';
import { videochatReducer } from './context/videochatReducer';
import GlobalStyles from './globals/GlobalStyles';
import { getAppVersion, getThemeConfig } from './globals/global';
import { getTheme } from './globals/theme';
import { useLanguage } from './hooks/useLanguage';
import { getAppProperties } from './services/RestServices';
import { showAlertMessage } from './utils/alertHandling';
import { setRuleProperties } from './utils/executeRuleUtils';
import { setDataFromAppProperties } from './utils/pcpUtils';
import { getQueryParams } from './utils/urlUtils';

declare global {
    interface Window {
        app: any;
    }
}

window.app = window.app || {};

function App() {
    const navigate = useNavigate();
    moment.locale('de');

    const [state, dispatch] = useReducer(loginReducer, initialLoginState);
    const [alertstate, alertdispatch] = useReducer(alertReducer, initialAlertState);
    const [vsstate, vsdispatch] = useReducer(videochatReducer, initialVideochatState);
    const [tmstate, tmdispatch] = useReducer(terminierungReducer, initialTerminierungState);
    const [docstate, docdispatch] = useReducer(dokumenteReducer, initialDokumenteState);
    const [qstate, qdispatch] = useReducer(questionnaireReducer, initialQuestionnaireState);
    const [apstate, apdispatch] = useReducer(appPropsReducer, InitialAppPropsState);
    const [themestate, themedispatch] = useReducer(themeReducer, initialThemeState);
    const [executerulestate, executeruledispatch] = useReducer(executeRuleReducer, initialExecuteRuleState);
    const [sigpadstate, sigpaddispatch] = useReducer(sigpadReducer, initialSigPadState);
    const [iframestate, iframedispatch] = useReducer(iframeReducer, initialIFrameState);
    const [checkinstate, checkindispatch] = useReducer(checkinReducer, initialCheckInState);

    const location = useLocation();

    const { getSystemLanguageData, setSystemLanguage } = useLanguage();

    const refreshPage = () => {
        window.location.reload();
    };

    const getProperties = async () => {
        const propRes = await getAppProperties();
        if (propRes) {
            setDataFromAppProperties(propRes, apdispatch);

            /* Google Analytics ID Init */
            if (propRes.googleAnalyticsID) {
                ReactGA.initialize(propRes.googleAnalyticsID);
            }
        } else {
            showAlertMessage({
                alertTitle: 'Fehler beim Laden der App',
                alertTxt:
                    'Die Seite konnte nicht geladen werden. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.',
                alertType: AlertType.error,
                onHide: AlertOnHide.custom,
                onHideCustom: refreshPage,
                alertdispatch: alertdispatch,
                buttonName: 'Erneut versuchen',
            });
        }
    };

    useEffect(() => {
        if (!(window as any).app.language) {
            const systemLanguage = getSystemLanguageData();
            (window as any).app.language = systemLanguage.id;
            setSystemLanguage();
        }
        /* AppProperties */
        getProperties();

        /* allow Vidochat messages */
        window.app.allowVideochatMessages = true;
        // const hashParams = getQueryParams(location.hash);
        const hashParams = getQueryParams(location.search);
        if (hashParams.type === 'Redirect') {
            if (state.redirectUser === '') {
                dispatch({
                    type: 'SETREDIRECTUSER',
                    redirectUser: hashParams.user,
                    redirectPwd: hashParams.pwd,
                });
                // history.replace(Paths.HOME);
                navigate(Paths.HOME, { replace: true });
            }
        }
        if (hashParams.type === 'gdt') {
            if (hashParams.id && hashParams.id.length > 0) {
                dispatch({
                    type: 'SETGDTDATA',
                    gdt: {
                        id: hashParams.id,
                    },
                });
            }
        }
        if (location.hash.lastIndexOf('#/ExecuteRule') > -1) {
            if (hashParams.type === 'ExecuteRule') {
                setRuleProperties(hashParams, executeruledispatch);
                // history.replace(Paths.HOME);
                navigate(Paths.HOME, { replace: true });
            }
        }
        let theme = 'PCP';
        if (hashParams.theme) {
            const pTheme = hashParams.theme;
            if (pTheme.length > 0) {
                theme = pTheme;
            }
        } else {
            theme = getThemeConfig();
        }
        themedispatch({
            type: 'SETTHEME',
            theme: theme,
        });
    }, []);

    return (
        <ErrorBoundary>
            <ThemeProvider theme={getTheme(themestate.theme)}>
                <GlobalStyles />
                <AlertContext.Provider value={{ alertstate, alertdispatch }}>
                    <LoginContext.Provider value={{ state, dispatch }}>
                        <VideochatContext.Provider value={{ vsstate, vsdispatch }}>
                            <TerminierungContext.Provider value={{ tmstate, tmdispatch }}>
                                <DokumenteContext.Provider value={{ docstate, docdispatch }}>
                                    <QuestionnaireContext.Provider value={{ qstate, qdispatch }}>
                                        <AppPropsContext.Provider value={{ apstate, apdispatch }}>
                                            <ThemeContext.Provider value={{ themestate, themedispatch }}>
                                                <CheckInContext.Provider value={{ checkinstate, checkindispatch }}>
                                                    <ExecuteRuleContext.Provider
                                                        value={{ executerulestate, executeruledispatch }}
                                                    >
                                                        <SigPadContext.Provider value={{ sigpadstate, sigpaddispatch }}>
                                                            <IFrameContext.Provider
                                                                value={{ iframestate, iframedispatch }}
                                                            >
                                                                <div className="App" data-appversion={getAppVersion()}>
                                                                    <AlertModal />
                                                                    <AppRoutes />
                                                                </div>
                                                            </IFrameContext.Provider>
                                                        </SigPadContext.Provider>
                                                    </ExecuteRuleContext.Provider>
                                                </CheckInContext.Provider>
                                            </ThemeContext.Provider>
                                        </AppPropsContext.Provider>
                                    </QuestionnaireContext.Provider>
                                </DokumenteContext.Provider>
                            </TerminierungContext.Provider>
                        </VideochatContext.Provider>
                    </LoginContext.Provider>
                </AlertContext.Provider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
