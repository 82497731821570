import { useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { ListGroupCst, ListGroupItemCst, StyledProfileCard } from '../../components/Cards/StyledCard';
import AppPropsContext from '../../context/appPropsContext';
import TerminierungContext from '../../context/terminierungContext';
import { useLanguage } from '../../hooks/useLanguage';
import { getGenderDE } from '../../utils/pcpUtils';

interface IPersonalDataSummary {
    width?: string;
}

const PersonalDataSummary = (props: IPersonalDataSummary) => {
    const { tmstate } = useContext(TerminierungContext);
    const { apstate } = useContext(AppPropsContext);

    const { t } = useLanguage();

    interface IDataItem {
        label: string;
        data: string;
    }

    const DataItem = (props: IDataItem) => {
        return (
            <ListGroupItemCst>
                <Row>
                    <Col sm={4}>{props.label}</Col>
                    <Col sm={8}>
                        <strong>{props.data}</strong>
                    </Col>
                </Row>
            </ListGroupItemCst>
        );
    };

    const getPlannedStudiesList = () => {
        let studyList = '';

        if (tmstate.personalData.plannedStudies.length > 0) {
            for (let i = 0; i < tmstate.personalData.plannedStudies.length; i++) {
                if (tmstate.personalData.plannedStudies[i].participate === true) {
                    if (i === 0) {
                        studyList = studyList + tmstate.personalData.plannedStudies[i].display;
                    } else {
                        studyList = studyList + ', ' + tmstate.personalData.plannedStudies[i].display;
                    }
                }
            }
        }

        return studyList;
    };

    return (
        <StyledProfileCard
            style={{ width: props.width ? props.width : '100%', marginBottom: '10px', marginTop: '0px' }}
        >
            <Card.Body style={{ textAlign: 'left' }}>
                <Card.Title>{t('ANMELD', 'Ihre persönlichen Daten')}</Card.Title>
                <Row>
                    <Col style={{ padding: 5 }}>
                        <Row>
                            <Container>
                                <Row>
                                    <Col>
                                        <ListGroupCst variant="flush">
                                            {apstate.RegistrationVisibleFields.academicTitle === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Titel')}
                                                    data={tmstate.personalData.academicTitle}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.firstName === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Vorname')}
                                                    data={tmstate.personalData.firstName}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.lastName === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Nachname')}
                                                    data={tmstate.personalData.lastName}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.birthDate === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Geburtstag')}
                                                    data={tmstate.personalData.birthday}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.gender === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Geschlecht')}
                                                    data={getGenderDE(tmstate.personalData.gender)}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.phone === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Telefonnummer')}
                                                    data={tmstate.personalData.telephone}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.mobilePhone === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Mobil-Telefonnummer')}
                                                    data={tmstate.personalData.mobilePhone}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.email === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'E-Mail')}
                                                    data={tmstate.personalData.email}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.zip === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Postleitzahl')}
                                                    data={tmstate.personalData.zip}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.city === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Ort')}
                                                    data={tmstate.personalData.city}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.street === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Straße')}
                                                    data={tmstate.personalData.street}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.street === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Hausnummer')}
                                                    data={tmstate.personalData.housenr}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.country === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Land')}
                                                    data={tmstate.personalData.country}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.insuranceNr === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Versichertennr.')}
                                                    data={tmstate.personalData.insuranceNr}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.insuranceName === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Krankenkasse')}
                                                    data={tmstate.personalData.insuranceName}
                                                />
                                            )}
                                            {apstate.RegistrationVisibleFields.language === true && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Sprache')}
                                                    data={tmstate.personalData.language}
                                                />
                                            )}
                                            {tmstate.personalData.plannedStudies.length > 0 && (
                                                <DataItem
                                                    label={t('PERSONALDATA', 'Gewünschte Studienteilnahmen')}
                                                    data={getPlannedStudiesList()}
                                                />
                                            )}
                                        </ListGroupCst>
                                    </Col>
                                </Row>
                            </Container>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </StyledProfileCard>
    );
};

export default PersonalDataSummary;
