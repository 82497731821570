import moment from 'moment';

export interface ISignPosData {
    left: any;
    top: any;
    width: any;
    height: any;
    page: number;
    modalMsg?: string;
}

export const getPDFDataFromDiagnosticReportResponse = (
    diagnosticReportResponse: any,
    filename: string,
    date: string,
) => {
    let modalTitle = '';
    let pdfdata = '';
    let pdfsrc = '';
    let isSignable = 'false';
    let isSigned = 'false';
    let signPosData = '';

    const diagReport =
        diagnosticReportResponse.data !== undefined ? diagnosticReportResponse.data : diagnosticReportResponse;

    if (diagReport.presentedForm) {
        if (date === '') {
            if (diagReport.effectiveDateTime) {
                date = moment(diagReport.effectiveDateTime).format('L LT');
            }
        }
        if (filename === '') {
            if (diagReport.code.coding[0].display) {
                filename = diagReport.code.coding[0].display;
            }
        }
        modalTitle = filename + ' / ' + date;
        pdfdata = diagReport.presentedForm[0].data;
        pdfsrc = 'data:application/pdf;base64,' + pdfdata;

        if (diagReport.extension) {
            diagReport.extension.forEach((e) => {
                if (e.url.endsWith('isSignable')) {
                    if (e.valueBoolean === true) {
                        isSignable = 'true';
                    }
                }
                if (e.url.endsWith('isSigned')) {
                    if (e.valueBoolean === true) {
                        isSigned = 'true';
                    }
                }
                if (e.url.endsWith('signPosData')) {
                    signPosData = e.valueString;
                }
            });
        }
    } else {
        modalTitle = 'Kein PDF zum anzeigen vorhanden';
    }

    return [modalTitle, pdfsrc, isSignable, isSigned, signPosData];
};
