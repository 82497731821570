import { Button } from 'react-bootstrap';
import { useLanguage } from '../../hooks/useLanguage';
import AppointmentPersonalData, { IAppointmentPersonalData } from './AppointmentPersonalData';

interface IAppointmentPersonalDataWithLogin extends IAppointmentPersonalData {
    showLogin: boolean;
    showRegistrationCbx: boolean;
    onLoginClick?(): void;
}

const AppointmentPersonalDataWithLogin = (props: IAppointmentPersonalDataWithLogin) => {
    const { t } = useLanguage();

    const handleLoginClick = (e: any) => {
        if (e) {
            e.currentTarget.blur();
        }
        if (props.onLoginClick) {
            props.onLoginClick();
        }
    };

    const showLogin = () => {
        if (props.isPhysPat) {
            return false;
        }
        if (props.showLogin) {
            return true;
        }
        return false;
    };

    return (
        <div>
            <div>
                {showLogin() && (
                    <p>
                        {t('ANMELD', 'Haben Sie bereits einen Account?')}
                        <Button variant="link" onClick={(e) => handleLoginClick(e)}>
                            {t('ANMELD', 'Hier anmelden')}
                        </Button>
                    </p>
                )}
            </div>
            <AppointmentPersonalData {...props} />
        </div>
    );
};

export default AppointmentPersonalDataWithLogin;
