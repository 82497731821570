import { useLanguage } from '../../hooks/useLanguage';
import { CenterDiv, ImpressumBottom } from '../Div/StyledDiv';
import { ParagraphFontSize } from '../StyledParagraph/StyledParagraph';

export interface IImpressumElement {
    impressumLink: string;
    datenschutzLink: string;
    textColor?: string;
}

const Impressum = (props: IImpressumElement) => {
    const { t } = useLanguage();

    const textColor = props.textColor ? props.textColor : '#007bff';

    return (
        <ImpressumBottom fontSize={ParagraphFontSize.ExtraSmall}>
            {props.impressumLink && (
                <a href={props.impressumLink} target="_blank" rel="noreferrer" style={{ color: textColor }}>
                    {t('COMMON', 'Impressum')}
                </a>
            )}
            {props.datenschutzLink && props.impressumLink && ' | '}
            {props.datenschutzLink && (
                <a href={props.datenschutzLink} target="_blank" rel="noreferrer" style={{ color: textColor }}>
                    {t('COMMON', 'Datenschutz')}
                </a>
            )}
        </ImpressumBottom>
    );
};

export const ImpressumNormal = (props: IImpressumElement) => {
    const { t } = useLanguage();

    const textColor = props.textColor ? props.textColor : '#007bff';
    return (
        <CenterDiv fontSize={ParagraphFontSize.ExtraSmall}>
            {props.impressumLink && (
                <a href={props.impressumLink} target="_blank" rel="noreferrer" style={{ color: textColor }}>
                    {t('COMMON', 'Impressum')}
                </a>
            )}
            {props.datenschutzLink && props.impressumLink && ' | '}
            {props.datenschutzLink && (
                <a href={props.datenschutzLink} target="_blank" rel="noreferrer" style={{ color: textColor }}>
                    {t('COMMON', 'Datenschutz')}
                </a>
            )}
        </CenterDiv>
    );
};

export default Impressum;
