import { useContext, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/esm/Row';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import AppPropsContext from '../../../context/appPropsContext';
import LoginContext from '../../../context/loginContext';
import { usePersonalData } from '../../../hooks/usePersonalData';
import { Paths } from '../../../Routes';
import { checkConsignation } from '../../../services/RestServices';
import StyledButton from '../../Buttons/StyledButton';
import StyledCol from '../../Cols/StyledCol';
import InitialPassword from '../../User/InitialPassword';
import { StyledModal } from '../StyledModal/StyledModal';

interface IDoDoubleOptInViaLinkModal {
    show: boolean;
    onHide: () => void;
}

const DoDoubleOptInViaLinkModal = (props: IDoDoubleOptInViaLinkModal) => {
    const navigate = useNavigate();
    const { apstate } = useContext(AppPropsContext);
    const { state } = useContext(LoginContext);
    const [consignationFinished, setConsignationFinished] = useState(false);
    const [initPassword, setInitPassword] = useState(false);
    const { getAndSetPersonalData } = usePersonalData();

    useEffect(() => {
        const isConsignationAccepted = async () => {
            const consigRes = await checkConsignation(state.sessionId);
            if (consigRes.consignation === 'patFinished') {
                clearInterval(interval);
                setConsignationFinished(true);
                if (consigRes.forceNewPWD === 'true') {
                    setInitPassword(true);
                }
            }
        };

        let interval;
        if (props.show) {
            interval = setInterval(() => {
                isConsignationAccepted();
            }, 1000);
        }

        // isConsignationAccepted();
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [props.show, consignationFinished]);

    const handlePWChange = () => {
        setInitPassword(false);
        props.onHide();
        getAndSetPersonalData(state.sessionId, state.activeUserId, state.practitionerRoleId);
        navigate(Paths.DOKUMENTE);
    };

    const handlePWCancel = () => {
        setInitPassword(false);
        props.onHide();
        navigate(Paths.HOME);
    };

    return (
        <div>
            <StyledModal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">Aktivierung Ihres Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Alert variant="danger">
                            <p>
                                Zur Verifizierung haben wir Ihnen eine E-Mail von {apstate.mailFromAddress} mit einem
                                Aktivierungslink zugesendet. Bitte überprüfen Sie Ihren E-Mail Posteingang und klicken
                                Sie auf den enthaltenen Link, um Ihren Account zu aktivieren. Prüfen Sie ggf. auch Ihren
                                Spam-Ordner.
                            </p>
                        </Alert>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <div style={{ float: 'left' }}>
                            <StyledCol textAlign="left">
                                <StyledButton variant="primary" onClick={props.onHide}>
                                    OK
                                </StyledButton>
                            </StyledCol>
                        </div>
                    </Row>
                </Modal.Footer>
            </StyledModal>
            {initPassword ? (
                <div>
                    <InitialPassword onchange={() => handlePWChange()} oncancel={() => handlePWCancel()} />
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DoDoubleOptInViaLinkModal;
