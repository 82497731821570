import { default as Select, StylesConfig } from 'react-select';

export interface ISelectComboboxOption {
    value: string;
    label: string;
}

export const createSelectComboboxOption = (option: string): ISelectComboboxOption => {
    return {
        value: option,
        label: option,
    };
};

export const createSelectComboboxOptions = (options: string[]): ISelectComboboxOption[] => {
    return options.map((option) => {
        return {
            value: option,
            label: option,
        };
    });
};

export const getReactSelectComboBoxDefaultStyles = () => {
    const colourStyles: StylesConfig = {
        control: (styles, { menuIsOpen, isFocused, isDisabled }) => ({
            ...styles,
            backgroundColor: 'white',
            boxShadow: menuIsOpen ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : undefined,
            borderColor: menuIsOpen ? '#80bdff' : '#ced4da',
            fontWeight: 400,
            lineHeight: '1.5',
            fontSize: '1rem',
            paddingLeft: '0.375rem',
            '&:hover': {
                borderColor: menuIsOpen ? '#80bdff' : '#ced4da',
            },
        }),
        placeholder: (styles, {}) => {
            return {
                ...styles,
                color: '#495057',
            };
        },
        dropdownIndicator: (styles, {}) => {
            return {
                ...styles,
                color: '#60666C',
                width: '1.97rem',
            };
        },
        indicatorsContainer: (styles, {}) => {
            return {
                ...styles,
            };
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? 'rgb(25, 103, 210)'
                    : isFocused
                    ? 'rgb(25, 103, 210)'
                    : undefined,
                color: isDisabled ? '#495057' : isSelected ? 'white' : isFocused ? 'white' : '#495057',
                fontWeight: 400,
                lineHeight: '1.5',
                fontSize: '1rem',
            };
        },
    };

    return colourStyles;
};

export const SelectCombobox = (props: React.ComponentProps<typeof Select>) => {
    let style = getReactSelectComboBoxDefaultStyles();
    if (props.styles) {
        style = props.styles;
    }
    return <Select {...props} styles={style} />;
};
