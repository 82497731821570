import { useContext, useEffect } from 'react';
import LoginContext from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import { getMacroNameFromSelectedResource } from '../../utils/appointmentUtils';
import { LeftDiv } from '../Div/StyledDiv';
import AppointmentCalendar from './AppointmentCalendar';

interface IAppCalHead {
    terminName?: string;
    handleTimelotClick?(currentActiveStep: number): void;
    currentActiveStep?: number;
}

const AppointmentCalendarWithHeadline = (props: IAppCalHead) => {
    const { state } = useContext(LoginContext);
    const { tmstate } = useContext(TerminierungContext);
    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    const { t } = useLanguage();

    useEffect(() => {
        sendGoogleAnalyticsPageView({ page: '/AppointmentCalendarWithHeadline', title: 'Termin wählen' });
    }, []);

    useEffect(() => {
        if (props.handleTimelotClick) {
            if (tmstate.selectedTimeslots && tmstate.selectedTimeslots.length > 0 && tmstate.timeslotClicked) {
                props.handleTimelotClick(props.currentActiveStep ? props.currentActiveStep : 0);
            }
        }
    }, [tmstate]);

    let terminName = t('ANMELD', 'Termin');
    if (props.terminName) {
        terminName = props.terminName;
    }

    let categoryName = tmstate.selectedCategory.name;
    if (!categoryName) {
        if (tmstate.selectedReason?.reasonName && tmstate.selectedReason?.reasonName !== undefined) {
            categoryName = tmstate.selectedReason.reasonName;
        } else if (tmstate.selectedCategory?.name && tmstate.selectedCategory?.name !== undefined) {
            categoryName = tmstate.selectedCategory.name;
        } else {
            categoryName = getMacroNameFromSelectedResource(tmstate.selectedResources[0]);
        }
    }

    const getDayTimeTextForLanguage = () => {
        if (state.activeLanguage?.language?.id === 'de') {
            return (
                <p>
                    {t('ANMELD', 'Wählen Sie Tag und Uhrzeit für')} <strong>{categoryName}</strong> aus
                </p>
            );
        }
        if (state.activeLanguage?.language?.id === 'en') {
            return (
                <p>
                    {t('ANMELD', 'Wählen Sie Tag und Uhrzeit für')} <strong>{categoryName}</strong>
                </p>
            );
        }
    };

    return (
        <LeftDiv>
            <h3>{t('ANMELD', 'Termin wählen', { appointmentName: terminName })}</h3>
            <p>{getDayTimeTextForLanguage()}</p>
            <p>
                {t('ANMELD', 'Behandler')}: <strong>{tmstate.selectedPhysician.name}</strong>
            </p>
            <AppointmentCalendar />
        </LeftDiv>
    );
};

export default AppointmentCalendarWithHeadline;
