import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import SigPadContext, { ISigData } from '../../../context/sigpadContext';
import { ISignaturePad, SignaturePadDeviceName, useSignaturePad } from '../../../hooks/useSignaturePad';
import { ISignotec } from '../../../hooks/useSignotecConnector';
import { ISignPosData } from '../../../utils/documentUtils';
import StyledButton from '../../Buttons/StyledButton';
import { EmbeddedSignDocumentModalContainer } from '../../Div/StyledDiv';
import SignaturePad from '../../SignaturePad/SignaturePad';
import SignaturePadSignotec from '../../SignaturePad/SignaturePadSignotec';

export const ModalCst = styled(Modal)`
    .modal-dialog {
        max-width: 90%;
        max-height: 90%;
        width: 90%;
        height: 90%;
        margin: 1.75rem auto;
        margin-top: 100px;
    }
    .modal-content {
        border-radius: ${(prop) => (prop.borderRadius ? prop.borderRadius : prop.theme.modal.borderRadius)};
        box-shadow: ${(prop) => (prop.boxShadow ? prop.boxShadow : prop.theme.modal.boxShadow)};
    }
`;

export interface ISignDocumentModalShow {
    show: boolean;
}

export interface ISignDocumentModal {
    show: boolean;
    embedded: boolean;
    onSign(signData: ISigData): void;
    onCancel(): void;
    onClickClear?: () => void; // clear signature pad done
    clickSign?: boolean;
    clickClear?: boolean;
    signPosData?: ISignPosData;
    title?: string | null;
}

const SignDocumentModal = (props: ISignDocumentModal) => {
    const { sigpadstate, sigpaddispatch } = useContext(SigPadContext);

    const [mouseSignData, setMouseSignData] = useState<ISigData>({
        certId: '',
        pictureData: '',
        rsaSignature: '',
        signData: '',
        signingCert: '',
    });

    const [sendClicked, setSendClicked] = useState(false);

    const signotecProps: ISignotec = {
        wsUri: 'wss://local.signotecwebsocket.de:49494',
    };
    const signaturePadProps: ISignaturePad = { signotecProps: signotecProps };
    const { loadSignaturePad, unloadSignaturePad, clearSignaturePad, sendSignaturePad } =
        useSignaturePad(signaturePadProps);

    useEffect(() => {
        if (props.show) {
            loadSignaturePad();
        }

        return () => {
            unloadSignaturePad(sigpadstate.deviceData.deviceName);
        };
    }, [props.show]);

    useEffect(() => {
        if (sendClicked) {
            if (sigpadstate.sigData.pictureData && sigpadstate.sigData.pictureData.length > 0) {
                /* Signotec Sign Data */
                props.onSign(sigpadstate.sigData);
                setSendClicked(false);
            } else if (mouseSignData.pictureData && mouseSignData.pictureData.length > 0) {
                /* Default Mouse Sign Data*/
                props.onSign(mouseSignData);
                setSendClicked(false);
            }
        }
    }, [sigpadstate.sigData, mouseSignData, sendClicked]);

    /* ## DEVICE ACTIONS BEGIN ## */
    useEffect(() => {
        if (sigpadstate?.deviceActions?.sendClicked) {
            onSignatureSend();
            sigpaddispatch({
                type: 'RESETDEVICEACTIONS',
            });
        }
        if (sigpadstate?.deviceActions?.cancelClicked) {
            onSignatureCancel();
            sigpaddispatch({
                type: 'RESETDEVICEACTIONS',
            });
        }
    }, [sigpadstate.deviceActions]);

    /* ## DEVICE ACTIONS END ## */

    const SignaturePadComponent = () => {
        if (
            sigpadstate.deviceData.deviceName === SignaturePadDeviceName.SIGNOTEC_SIGMA ||
            sigpadstate.deviceData.deviceName === SignaturePadDeviceName.SIGNOTEC_GAMMA
        ) {
            /* Signotec Pad */
            return (
                <SignaturePadSignotec
                    deviceData={sigpadstate.deviceData}
                    onClear={onSignatureClear}
                    onCancel={props.embedded ? onSignatureCancel : undefined}
                    onSign={props.embedded ? onSignatureSend : undefined}
                    height={500}
                    width={800}
                    margin="auto"
                />
            );
        } else {
            /* withoutDevice - Standard SignaturePad to use with mouse */
            return (
                <SignaturePad
                    onWriteEnd={onSignaturePadWriteEnd}
                    onCancel={props.embedded ? onSignatureCancel : undefined}
                    onSign={props.embedded ? onSignatureSend : undefined}
                    onClickClear={props.onClickClear ? props.onClickClear : undefined}
                    clickClear={props.clickClear}
                    clickSign={props.clickSign}
                    embedded={props.embedded}
                    height={props.embedded ? props.signPosData?.height : 500}
                    width={props.embedded ? props.signPosData?.width : 800}
                    offsetTop={props.signPosData?.top}
                    offsetLeft={props.signPosData?.left}
                    margin="auto"
                />
            );
        }
    };

    const onSignaturePadWriteEnd = (sigData) => {
        setMouseSignData(sigData);
    };

    const onSignatureCancel = () => {
        unloadSignaturePad(sigpadstate.deviceData.deviceName);
        props.onCancel();
    };

    const onSignatureClear = (canvasRef: any) => {
        clearSignaturePad(sigpadstate.deviceData.deviceName, canvasRef);
    };

    const onSignatureSend = () => {
        sendSignaturePad(sigpadstate.deviceData.deviceName);
        setSendClicked(true);
    };

    return props.embedded && props.show ? (
        <EmbeddedSignDocumentModalContainer>{SignaturePadComponent()}</EmbeddedSignDocumentModalContainer>
    ) : (
        <ModalCst show={props.show} id="signModal">
            <Modal.Header>
                <Modal.Title>{props.title ? props.title : 'Signieren'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{SignaturePadComponent()}</Modal.Body>
            <Modal.Footer>
                <StyledButton variant="secondary" onClick={() => onSignatureCancel()}>
                    Abbrechen
                </StyledButton>
                <StyledButton style={{ marginLeft: '40px' }} onClick={() => onSignatureSend()}>
                    Jetzt signieren
                </StyledButton>
            </Modal.Footer>
        </ModalCst>
    );
};

export default SignDocumentModal;
