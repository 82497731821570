import moment from 'moment';
import { useContext, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import TerminierungContext, {
    IAvailableTimeslot,
    IExtendedPhysicianAppointment,
    IExtendedPhysicianAppointmentExtension,
} from '../../context/terminierungContext';
import { useAppointmentSelection } from '../../hooks/useAppointmentSelection';
import { useLanguage } from '../../hooks/useLanguage';
import useScreenResolution from '../../hooks/useScreenResolution';
import { blockAppointment } from '../../services/RestServices';
import {
    setReplacedCareUnitForResource,
    setReplacedDepartmentForResource,
    setReplacedPractitionerForResource,
    setReplacedRoomForResource,
    setReplacedServiceTypeForResource,
    setReplacedStartEndForResource,
} from '../../utils/appointmentUtils';
import { TimeslotButton } from '../Buttons/SelectButton';
import { StyledPhysicianSelectionCard, StyledPhysicianSelectionCardTitle } from '../Cards/StyledCard';
import { DateSwitcherTimeslotCol, DateSwitcherTimeslotColStartEnd } from '../Cols/StyledCol';
import { VerticalCenterDiv } from '../Div/StyledDiv';
import LoadingSpinnerWithText from '../Loading/LoadingSpinnerWithText';
import BlockingAppointmentModal from '../Modals/BlockingAppointmentModal/BlockingAppointmentModal';
import { TimeslotRow } from '../Rows/StyledRow';
import PhysicianSelectionDescriptionList from './PhysicianSelectionDescriptionList';
import PhysicianSelectionPhoto from './PhysicianSelectionPhoto';

type IPhyscianSelectionCardWithAppointments = {
    name?: string;
    description?: string[];
    showPicture?: boolean;
    pict?: string;
    startDate: Date;
    countDaysToVisualize: number;
    maxTimeSlots: number;
    appointments?: IExtendedPhysicianAppointment[];
    blockMessage?: string;
    hdBooking?: string;
    handleTimelotClick?(currentActiveStep: number): void;
    currentActiveStep?: number;
    showLoadingSpinner: boolean;
    firstPossibleDate?: string;
    handleStartDate(firstPossibleDate?: string): void;
};

const PhysicianSelectionCardWithAppointments = (props: IPhyscianSelectionCardWithAppointments) => {
    const { tmstate, tmdispatch } = useContext(TerminierungContext);

    const [showMoreButton, setShowMoreButton] = useState<boolean>(false);
    const [showAllTimeSlots, setShowAllTimeSlots] = useState<boolean>(false);

    const { getSelectedResource, setSelectedResource, setSelectedTimeslot, getMakroIdFromResourceServiceType } =
        useAppointmentSelection();

    const screenSize = useScreenResolution();

    const { t } = useLanguage();

    interface IBlockingModalShow {
        show: boolean;
    }
    const [blockingModalShow, setBlockingModalShow] = useState<IBlockingModalShow>({
        show: false,
    });

    const getAppointmentForDate = (date: string, appointments: IExtendedPhysicianAppointment[]) => {
        const appointment = appointments.filter((e) => {
            const myDate = moment(e.start).format('DD.MM.yyyy');
            if (myDate === date) {
                return true;
            }
        });

        return appointment;
    };

    const getTimeSlotsForDate = (appointment: IExtendedPhysicianAppointmentExtension[]) => {
        const timeslots = appointment.filter((e) => {
            if (e.url.endsWith('possibleTimes')) {
                return true;
            }
        });

        return timeslots;
    };

    const clickedTimeslot = async (
        resourceAppointment: IExtendedPhysicianAppointment,
        timeslot: IAvailableTimeslot,
        supressDuration: boolean,
    ) => {
        // console.log('clicked timeslot: start: ', dateStart, ' end: ', dateEnd);
        const selectedTimeslot = {
            start: timeslot.timeslot.start,
            end: timeslot.timeslot.end,
            supressDuration: supressDuration,
        };

        let updatedResource = setReplacedRoomForResource(resourceAppointment, timeslot.replacedRoom);
        updatedResource = setReplacedStartEndForResource(updatedResource, selectedTimeslot);
        updatedResource = setReplacedPractitionerForResource(updatedResource, timeslot.replacedPractitioner);
        updatedResource = setReplacedCareUnitForResource(updatedResource, timeslot.replacedCareUnit);
        updatedResource = setReplacedDepartmentForResource(updatedResource, timeslot.replacedDepartment);
        updatedResource = setReplacedServiceTypeForResource(updatedResource, timeslot.replacedServiceType);

        setSelectedResource(tmstate.workOnAppointmentNr, updatedResource);

        const resource = getSelectedResource(1);
        const blockResponse = await blockAppointment(resource);
        if (blockResponse.success === 'false') {
            // onNewAppointments(blockResponse.newApp, tmstate.workOnAppointmentNr, setSelectedTimeslot);
            // gotNewAppointment = true;
            setBlockingModalShow({
                show: true,
            });
            return;
        }

        setSelectedTimeslot(tmstate.workOnAppointmentNr, selectedTimeslot);

        /* Fruehester Termin -> get makroId from ServiceType and find resource from filteredResources */
        if (tmstate.selectedReason?.makroId !== undefined && tmstate.selectedReason.makroId === 0) {
            const makroId = getMakroIdFromResourceServiceType(updatedResource);

            if (makroId && makroId > 0) {
                const filteredSelectedReason = tmstate.filteredReasons.filter((e) => e.makroId === makroId);
                tmdispatch({
                    type: 'SELECTREASON',
                    selectedReason: filteredSelectedReason[0],
                });
            }
        }

        if (props.handleTimelotClick) {
            // if (tmstate.selectedTimeslots && tmstate.selectedTimeslots.length > 0 && tmstate.timeslotClicked) {
            //     props.handleTimelotClick(props.currentActiveStep ? props.currentActiveStep : 0);
            // }
            tmdispatch({
                type: 'HDBOOKING',
                hdBooking: props.hdBooking ? props.hdBooking : '',
            });
            props.handleTimelotClick(props.currentActiveStep ? props.currentActiveStep : 0);
        }
    };

    const getTime = (date: Date) => {
        return moment(date).format('HH:mm');
    };

    const getTimeRange = (slot: IAvailableTimeslot, supressDuration: boolean) => {
        if (supressDuration) {
            return getTime(slot.timeslot.start);
        }
        return getTime(slot.timeslot.start) + ' - ' + getTime(slot.timeslot.end);
    };

    const rowNrToShow = (
        rowNr: number,
        maxRowNr: number,
        allPossibleTimeslots: IExtendedPhysicianAppointmentExtension[],
    ) => {
        if (isNaN(maxRowNr)) {
            return rowNr;
        }
        if (maxRowNr >= allPossibleTimeslots.length) {
            return rowNr;
        }
        if (rowNr == maxRowNr - 1) {
            return allPossibleTimeslots.length - 1;
        }
        const stepWith = allPossibleTimeslots.length / (maxRowNr - 1);
        return Math.floor(rowNr * stepWith);
    };

    const timeslotCol = (rowNr: number, maxRowNr: number) => {
        const allCols: any[] = [];

        allCols.push(<DateSwitcherTimeslotColStartEnd key="timeslotBeginCol"></DateSwitcherTimeslotColStartEnd>);
        for (let i = 0; i < props.countDaysToVisualize; i++) {
            let color = '#f0f0f0';
            if (i % 2 !== 0) {
                color = 'white';
            }

            const myDate = moment(props.startDate).add(i, 'days');
            const myDateFormated = myDate.format('DD.MM.yyyy');
            if (props.appointments && props.appointments.length > 0) {
                const appointments = getAppointmentForDate(
                    myDateFormated,
                    props.appointments ? props.appointments : [],
                );
                if (appointments.length > 0) {
                    const appointment = appointments[0];
                    if (appointment.extension) {
                        const allPossibleTimeslots = getTimeSlotsForDate(appointment.extension);

                        if (allPossibleTimeslots.length > props.maxTimeSlots) {
                            if (showMoreButton === false) {
                                setShowMoreButton(true);
                            }
                        }

                        const row2Show = rowNrToShow(rowNr, maxRowNr, allPossibleTimeslots);
                        const possibleTimeslots = allPossibleTimeslots[row2Show];

                        if (possibleTimeslots?.url.endsWith('possibleTimes')) {
                            if (possibleTimeslots.extension) {
                                const container = {} as IAvailableTimeslot;

                                possibleTimeslots.extension.forEach((subExtension) => {
                                    if (subExtension.url.endsWith('timeSlot') && subExtension.valuePeriod) {
                                        container.timeslot = subExtension.valuePeriod;
                                    }
                                    if (subExtension.url.endsWith('replacedRoom') && subExtension.valueReference) {
                                        container.replacedRoom = subExtension.valueReference;
                                    }
                                    if (
                                        subExtension.url.endsWith('replacedPractitioner') &&
                                        subExtension.valueReference
                                    ) {
                                        container.replacedPractitioner = subExtension.valueReference;
                                    }
                                    if (subExtension.url.endsWith('replacedCareunit') && subExtension.valueReference) {
                                        container.replacedCareUnit = subExtension.valueReference;
                                    }
                                    if (
                                        subExtension.url.endsWith('replacedDepartment') &&
                                        subExtension.valueReference
                                    ) {
                                        container.replacedDepartment = subExtension.valueReference;
                                    }
                                    if (
                                        subExtension.url.endsWith('replacedServiceType') &&
                                        subExtension.valueCodeableConcept
                                    ) {
                                        container.replacedServiceType = subExtension.valueCodeableConcept;
                                    }
                                });

                                allCols.push(
                                    <DateSwitcherTimeslotCol
                                        key={'row_' + rowNr + '_' + myDateFormated + '_' + container.timeslot.start}
                                        style={{ textAlign: 'center', backgroundColor: color }}
                                    >
                                        <TimeslotButton
                                            selected={false}
                                            onClick={() => clickedTimeslot(appointment, container, true)}
                                        >
                                            {getTimeRange(container, true)}
                                        </TimeslotButton>
                                        {/* <TimeslotButton
                                            selected={false}
                                            onClick={() => clickedTimeslot(appointment, container, true)}
                                        >
                                            {getTimeRange(container, true)}
                                        </TimeslotButton> */}
                                    </DateSwitcherTimeslotCol>,
                                );
                            }
                        } else {
                            allCols.push(
                                <DateSwitcherTimeslotCol
                                    key={'row_' + rowNr + '_' + myDateFormated}
                                    style={{ textAlign: 'center', backgroundColor: color }}
                                >
                                    <TimeslotButton selected={false} style={{ visibility: 'hidden' }}>
                                        00:00
                                    </TimeslotButton>
                                </DateSwitcherTimeslotCol>,
                            );
                        }
                    }
                } else {
                    allCols.push(
                        <DateSwitcherTimeslotCol
                            key={'row_' + rowNr + '_' + myDateFormated}
                            style={{ textAlign: 'center', backgroundColor: color }}
                        >
                            <TimeslotButton selected={false} style={{ visibility: 'hidden' }}>
                                00:00
                            </TimeslotButton>
                        </DateSwitcherTimeslotCol>,
                    );
                }
            }
        }
        allCols.push(<DateSwitcherTimeslotColStartEnd key="timeslotEndCol"></DateSwitcherTimeslotColStartEnd>);

        return allCols;
    };

    const timeslots = (appointments: IExtendedPhysicianAppointment[]) => {
        const allTimeslots: JSX.Element[] = [];

        if (showAllTimeSlots) {
            let maxRows = 7;
            appointments.forEach((e) => {
                let currentMax = 0;
                e.extension?.forEach((ext) => {
                    if (ext.url.endsWith('possibleTimes')) {
                        currentMax++;
                    }
                });

                if (currentMax > maxRows) {
                    maxRows = currentMax;
                }
            });
            for (let i = 0; i < maxRows; i++) {
                allTimeslots.push(<TimeslotRow key={i}>{timeslotCol(i, NaN)}</TimeslotRow>);
            }
        } else {
            for (let i = 0; i < props.maxTimeSlots; i++) {
                allTimeslots.push(<TimeslotRow key={i}>{timeslotCol(i, props.maxTimeSlots)}</TimeslotRow>);
            }
        }

        return allTimeslots;
    };

    const ShowMoreButton = () => {
        return (
            <Row style={{ marginTop: '10px' }}>
                <Col key={'row_moreAppointments'} style={{ textAlign: 'center' }}>
                    <Button variant="link" onClick={() => setShowAllTimeSlots(!showAllTimeSlots)}>
                        {showAllTimeSlots
                            ? t('ANMELD', 'Weniger Termine anzeigen')
                            : t('ANMELD', 'Mehr Termine anzeigen')}
                    </Button>
                </Col>
            </Row>
        );
    };

    const BlockMessage = () => {
        if (props.blockMessage) {
            return (
                <div>
                    <div dangerouslySetInnerHTML={{ __html: props.blockMessage }} />
                </div>
            );
        }
        return null;
    };

    const jumpToDate = (firstPossibleDate?: string) => {
        props.handleStartDate(firstPossibleDate);
    };

    const FirstPossibleDateInTimeSlot = () => {
        if (props.firstPossibleDate && screenSize.width >= 677) {
            return (
                <VerticalCenterDiv style={{ marginTop: '10px' }}>
                    <FirstPossibleDateContent />
                </VerticalCenterDiv>
            );
        }
        return null;
    };

    const FirstPossibleDateInDescrList = () => {
        if (props.firstPossibleDate && screenSize.width < 677) {
            return (
                <div style={{ marginTop: '10px' }}>
                    <FirstPossibleDateContent />
                </div>
            );
        }
        return null;
    };

    const FirstPossibleDateContent = () => {
        return (
            <p style={{ fontWeight: 'bold' }}>
                Nächster Freier Termin: &nbsp;
                <Button
                    variant="link"
                    style={{ fontSize: '0.85rem', fontWeight: 'bold', paddingTop: 0, paddingLeft: 0 }}
                    onClick={() => jumpToDate(props.firstPossibleDate)}
                >
                    {props.firstPossibleDate}
                </Button>
            </p>
        );
    };

    const TimeSlotArea = () => {
        if (props.showLoadingSpinner) {
            return (
                <div>
                    <LoadingSpinnerWithText loadingText="Termine werden geladen..." />
                </div>
            );
        }
        if (props.blockMessage) {
            return <BlockMessage />;
        } else if (props.firstPossibleDate) {
            return <FirstPossibleDateInTimeSlot />;
        } else {
            return (
                <>
                    {timeslots(props.appointments ? props.appointments : [])}
                    {showMoreButton && props.appointments && props.appointments.length > 0 && <ShowMoreButton />}
                </>
            );
        }
    };

    return (
        <>
            <StyledPhysicianSelectionCard>
                <Card.Body style={{ textAlign: 'left', overflowY: showAllTimeSlots ? 'auto' : 'unset' }}>
                    <Row>
                        <Col
                            md={2}
                            sm={4}
                            style={{ marginRight: '20px', width: '160px', flexBasis: '160px', minWidth: '160px' }}
                        >
                            <PhysicianSelectionPhoto showPicture={props.showPicture} pict={props.pict} />
                        </Col>
                        <Col md={3} sm={3} style={{ minWidth: '230px' }}>
                            {props.name && (
                                <StyledPhysicianSelectionCardTitle>{props.name}</StyledPhysicianSelectionCardTitle>
                            )}
                            <PhysicianSelectionDescriptionList
                                description={props.description}
                                firstFreeDateElement={<FirstPossibleDateInDescrList />}
                            />
                        </Col>
                        <Col style={{ textAlign: 'center' }}>
                            <TimeSlotArea />
                        </Col>
                    </Row>
                </Card.Body>
            </StyledPhysicianSelectionCard>
            <BlockingAppointmentModal
                show={blockingModalShow.show}
                onHide={() => setBlockingModalShow({ show: false })}
            />
        </>
    );
};

export default PhysicianSelectionCardWithAppointments;
