import { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { TbWorld } from 'react-icons/tb';
import { JsxElement } from 'typescript';
import AppPropsContext from '../../context/appPropsContext';
import LoginContext, { ILanguage } from '../../context/loginContext';
import { useLanguage } from '../../hooks/useLanguage';
import StyledButton from '../Buttons/StyledButton';

const LanguageSwitch = () => {
    const [allLanguages, setAllLanguages] = useState<JsxElement[]>([]);

    const { state } = useContext(LoginContext);
    const { apstate } = useContext(AppPropsContext);
    const { setActiveLanguage, getAvailableLanguages } = useLanguage();

    useEffect(() => {
        getDropdownItems();
    }, [state.activeLanguage, apstate.availableLanguages]);

    const handleLanguageSwitch = async (e: any, language: ILanguage) => {
        if (e && e.currentTarget) {
            e.preventDefault();
            e.currentTarget.blur();
        }
        setActiveLanguage(language);
    };

    const getDropdownItems = () => {
        const allItems: any[] = [];
        const availableLanguages = getAvailableLanguages();

        if (Array.isArray(availableLanguages) && availableLanguages.length) {
            availableLanguages.forEach((language: ILanguage) => {
                allItems.push(
                    <Dropdown.Item key={language.id} onClick={(e) => handleLanguageSwitch(e, language)}>
                        {language.name}
                    </Dropdown.Item>,
                );
            });
        }

        setAllLanguages(allItems);
    };

    return (
        <Dropdown style={{ visibility: allLanguages?.length > 1 ? 'visible' : 'hidden' }}>
            <Dropdown.Toggle as={StyledButton} variant="secondary" margintop="1px" id="dropdown-basic">
                <Badge>
                    <TbWorld size={20} />
                </Badge>
                {state.activeLanguage.language.id}
            </Dropdown.Toggle>
            <Dropdown.Menu>{allLanguages}</Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageSwitch;
