import { useContext, useEffect, useState } from 'react';
import BadgesList from '../../components/Badges/BadgesList';
import { LeftDivMain } from '../../components/Div/StyledDiv';
import { StyledMainHeader } from '../../components/StyledText/StyledHeader';
import AlertContext from '../../context/alertContext';
import LoginContext, { IDepartments } from '../../context/loginContext';
import TerminierungContext from '../../context/terminierungContext';
import { useGoogleAnalytics } from '../../hooks/useGoogleAnalytics';
import { useLanguage } from '../../hooks/useLanguage';
import useScreenResolution from '../../hooks/useScreenResolution';
import { getParticipants } from '../../services/RestServices';

interface IAppointmentCategoryDepartment {
    departments?: IDepartments[];
    handleDepartmentClick(): void;
}

const AppointmentCategoryDepartment = (props: IAppointmentCategoryDepartment) => {
    const { alertdispatch } = useContext(AlertContext);
    const { dispatch } = useContext(LoginContext);
    const { tmdispatch } = useContext(TerminierungContext);
    const [departments, setDepartments] = useState<IDepartments[]>([]);

    const { t } = useLanguage();

    const { sendGoogleAnalyticsPageView } = useGoogleAnalytics();

    const screenSize = useScreenResolution();

    useEffect(() => {
        sendGoogleAnalyticsPageView({
            page: '/AppointmentCategoryDepartment',
            title: 'Fachrichtung wählen',
        });

        const getDepartmentMacros = async () => {
            if (props.departments) {
                setDepartments(props.departments);
            } else {
                const possibleMacrosResponse: any = await getParticipants(alertdispatch);
                if (possibleMacrosResponse.departments !== undefined) {
                    setDepartments(possibleMacrosResponse.departments);
                }
            }
        };

        getDepartmentMacros();
    }, []);

    useEffect(() => {
        if (departments && departments.length > 0) {
            dispatch({
                type: 'SETDEPARTMENTS',
                departments: departments,
            });
        }
    }, [departments]);

    const getBadgesList = () => {
        const allBadges: string[] = [];
        departments.forEach((e) => {
            allBadges.push(e.name);
        });

        return allBadges;
    };

    const handleDepartmentClick = (departmentName: string) => {
        const selectedDepartment = departments.find((dep) => dep.name === departmentName);
        if (selectedDepartment) {
            tmdispatch({
                type: 'SELECTDEPARTMENT',
                selectedDepartment: selectedDepartment,
            });
            props.handleDepartmentClick();
        }
    };

    return (
        <LeftDivMain>
            <StyledMainHeader>{t('ANMELD', 'Fachrichtung wählen')}</StyledMainHeader>
            <p>{t('ANMELD', 'Wählen Sie die gewünschte Fachrichtung aus')}</p>
            <br />
            <BadgesList
                badgesList={getBadgesList()}
                showAsGrid={screenSize.width < 440 ? true : false}
                handleBadgeClick={handleDepartmentClick}
            />
        </LeftDivMain>
    );
};

export default AppointmentCategoryDepartment;
